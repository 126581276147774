html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
.questionnaire-h1,
.questionnaire-h2 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1,
.questionnaire-h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.25;
}
h2,
.questionnaire-h2 {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.25;
}
h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.25;
}
h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.25;
}
h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.25;
}
h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}
a {
  display: inline-block;
  color: #5057ff;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}
a:hover {
  color: #000afb;
}
.faux-link {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.25s ease;
}
.faux-link.primary {
  color: #5057ff;
}
.faux-link.primary:hover {
  color: #2f37ff;
}
.faux-link.danger {
  color: #e30033;
}
.faux-link.danger:hover {
  color: #cc002e;
}
.faux-link.gray {
  color: #a1a9ae;
}
.faux-link.gray:hover {
  color: #8f999e;
}
.faux-link.borderless {
  border: 0;
}
.faux-link.borderless:hover {
  border: 0;
}
.text-center {
  text-align: center;
}
strong {
  font-weight: 700;
}
em {
  font-style: italic;
}
html,
body {
  width: 100%;
  min-height: 100%;
  font-family: "Inter", "Helvetica Neue", "Arial", sans-serif;
  font-size: 16px;
  background: #f5f6fa;
  color: #2d3e4e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
  background: transparent;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
#root,
#root > div {
  min-height: 100vh;
}
.container {
  width: 100%;
  max-width: 1376px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}
.container.legal-container {
  max-width: 810px;
}
.container.small,
.container.library {
  max-width: 1020px;
}
.container.landing-page,
.container.gift-page {
  max-width: 1200px;
}
.paper {
  width: 100%;
  padding: 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}
hr {
  width: 100%;
  height: 1px;
  background: #e6e7ec;
  border: none;
  margin: 40px auto;
}
img {
  display: inline-block;
  max-width: 100%;
}
.questionnaire__wrapper {
  padding-top: 48px;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  flex: 1;
}
.questionnaire__wrapper h1 {
  font-size: 48px;
  margin-bottom: 24px;
}
@media (max-width: 672px) {
  .questionnaire__wrapper {
    padding: 24px 16px;
  }
  .questionnaire__wrapper h1 {
    font-size: 32px;
  }
  .questionnaire__wrapper h5 {
    font-size: 16px;
  }
}
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px;
}
.loader svg {
  width: 64px;
  height: 64px;
}
.icon__wrapper {
  width: 24px;
  height: 24px;
}
.registration--page .header {
  width: 100%;
}
.registration--page .header__inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.registration--page--button__wrapper {
  font-weight: 500;
}
.registration--page--button__wrapper a {
  font-weight: 600;
}
.registration--page--button__wrapper > * + * {
  margin-top: 8px;
}
.register-form__wrapper {
  max-width: 600px;
  padding-bottom: 64px;
  padding-top: 16px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .register-form__wrapper {
    margin: 0 20px;
  }
}
.register-form__wrapper form > * + * {
  margin-top: 16px;
}
.register-form__wrapper form .button {
  display: block;
  width: 100%;
}
.register-form__wrapper .validation-error {
  margin-top: 0;
  margin-bottom: 32px;
}
.register-form--header {
  margin-bottom: 48px;
}
.register-form--header h1 {
  color: #000;
  font-weight: bold;
  text-align: center;
}
.register-form--bulletpoint {
  display: flex;
  align-items: center;
  margin: 8px auto;
}
.register-form--bulletpoint p {
  font-size: 16px;
  font-weight: 600;
}
.register-form--bulletpoint .check {
  margin-right: 5px;
  color: #008000;
}
.register-form--name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.register-form--name-field {
  flex: 1;
  margin-right: 1rem;
}
.register-form--name-field:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .register-form--name {
    flex-direction: column;
  }
  .register-form--name-field {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.fitter__footer {
  background-color: #eef0f7;
  padding: 48px 0;
  margin-top: 64px;
  text-align: center;
  color: #7a848b;
}
.main-layout,
.main-layout__children,
.auth-app,
.fitter-layout {
  height: 100vh;
}
.fitter-layout {
  display: flex;
  flex-direction: column;
}
.fitter-layout__children {
  flex: 1;
}
.questionnaire-stepper__wrapper {
  flex: 1;
}
.questionnaire-stepper__wrapper + .landing-page--footer {
  margin-top: 0;
}
.legal {
  padding-top: 48px;
  padding-bottom: 48px;
  line-height: 1.5;
}
.legal .underline {
  text-decoration: underline;
}
.legal h1,
.legal h2,
.legal h3,
.legal h4,
.legal h5,
.legal h6 {
  margin: 24px 0 16px;
}
.legal ul,
.legal ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}
.legal ul > * + *,
.legal ol > * + * {
  margin-top: 16px;
}
.legal ul {
  list-style: disc;
}
.legal ol {
  list-style: none;
  counter-reset: item;
}
.legal ol > li:before {
  content: counters(item, ".") ". ";
}
.legal ol > li {
  counter-increment: item;
}
.legal ol > li > ol > li::before {
  content: counters(item, ".") ". ";
}
.legal table {
  font-size: 16px;
  margin-top: 48px;
  overflow-x: scroll;
}
.legal table thead th {
  font-weight: 700;
  font-size: 18px;
}
.legal table thead th,
.legal table tbody td {
  padding: 8px 16px;
  vertical-align: top;
  color: #2d3e4e;
  text-align: left;
}
button {
  font-family: "Inter", "Helvetica Neue", "Arial", sans-serif;
  font-size: 16px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.button {
  width: auto;
  font-family: "Inter", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 600;
  display: inline-block;
  background-color: transparent;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.25;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  text-transform: capitalize;
  transition: border-color 150ms ease, background-color 150ms ease, color 150ms ease;
}
.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.button--icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.button--icon > * + * {
  margin-left: 8px;
}
.button--icon svg {
  fill: currentcolor;
}
.button--icon-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
}
.button--color--primary {
  border-color: #5057ff;
  background-color: #5057ff;
  color: #fff;
}
.button--color--primary:hover {
  color: #fff;
}
.button--color--primary:not(.button--disabled):hover {
  background: #3f47ff;
  border-color: #3f47ff;
}
.button--color--primary:not(.button--disabled):active {
  background: #2f37ff;
  border-color: #2f37ff;
}
.button--color--primary.button--theme--outline {
  background-color: transparent;
  color: #5057ff;
}
.button--color--primary.button--theme--outline:not(.button--disabled):hover {
  background-color: #3f47ff;
  border-color: #3f47ff;
  color: #fff;
}
.button--color--primary.button--theme--outline:not(.button--disabled):active {
  color: #fff;
  background-color: #2f37ff;
  border-color: #2f37ff;
}
.button--color--secondary {
  border-color: #248227;
  background-color: #248227;
  color: #fff;
}
.button--color--secondary:hover {
  color: #fff;
}
.button--color--secondary:not(.button--disabled):hover {
  background: #93e196;
  border-color: #93e196;
}
.button--color--secondary:active:not(.button--disabled) {
  background: #1b621d;
  border-color: #1b621d;
}
.button--color--secondary.button--theme--outline {
  background-color: #fff;
  color: #248227;
}
.button--color--secondary.button--theme--outline:not(.button--disabled):hover {
  background: #fff;
  color: #93e196;
}
.button--color--secondary.button--theme--outline:not(.button--disabled):active {
  color: #1b621d;
}
.button--color--danger {
  border-color: #e30033;
  background-color: #e30033;
  color: #fff;
}
.button--color--danger:hover {
  color: #fff;
}
.button--color--danger:not(.button--disabled):hover {
  background: #c1002b;
  border-color: #c1002b;
}
.button--color--danger:not(.button--disabled):active {
  background: #aa0026;
  border-color: #aa0026;
}
.button--color--danger.button--theme--outline {
  background-color: #fff;
  color: #e30033;
}
.button--color--danger.button--theme--outline:not(.button--disabled):hover {
  background: #fff;
  color: #ff8ea7;
}
.button--color--danger.button--theme--outline:not(.button--disabled):active {
  color: #aa0026;
}
.button--color--dark {
  border-color: #1d1d1d;
  background-color: #1d1d1d;
  color: #fff;
}
.button--color--dark:hover {
  color: #fff;
}
.button--color--dark:not(.button--disabled):hover {
  background: #a5a5a5;
  border-color: #a5a5a5;
}
.button--color--dark:active:not(.button--disabled) {
  background: #161616;
  border-color: #161616;
}
.button--color--dark.button--theme--outline {
  background-color: #fff;
  color: #1d1d1d;
}
.button--color--dark.button--theme--outline:not(.button--disabled):hover {
  background: #fff;
  color: #a5a5a5;
}
.button--color--dark.button--theme--outline:not(.button--disabled):active {
  color: #161616;
}
.button--color--light {
  border-color: #fff;
  background-color: #fff;
  color: #5057ff;
}
.button--color--light:hover {
  color: #5057ff;
}
.button--color--light:not(.button--disabled):hover {
  background: #fff;
  border-color: #fff;
}
.button--color--light:active:not(.button--disabled) {
  background: #bfbfbf;
  border-color: #bfbfbf;
}
.button--color--light.button--theme--outline {
  background-color: #fff;
  color: #5057ff;
}
.button--color--light.button--theme--outline:not(.button--disabled):hover {
  background: #fff;
  color: #b9bcff;
}
.button--color--light.button--theme--outline:not(.button--disabled):active {
  color: #000afb;
}
.button--color--white {
  border-color: #fff;
  background-color: #fff;
  color: #5057ff;
}
.button--color--white:hover {
  color: #5057ff;
}
.button--color--white:not(.button--disabled):hover {
  background: #f2f2f2;
  border-color: #f2f2f2;
  color: #2f37ff;
}
.button--color--white:active:not(.button--disabled) {
  background: #e1e2e8;
  border-color: #e1e2e8;
}
.button--color--white.button--theme--outline {
  background-color: #fff;
  color: #5057ff;
}
.button--color--white.button--theme--outline:not(.button--disabled):hover {
  background: #fff;
  color: #b9bcff;
}
.button--color--white.button--theme--outline:not(.button--disabled):active {
  color: #000afb;
}
.button--color--transparent {
  border-color: transparent;
  background-color: transparent;
  color: #5057ff;
  font-weight: 500;
  text-decoration: underline;
}
.button--color--transparent:hover {
  color: #5057ff;
  background-color: #f5f6fa;
}
.button--color--transparent__gray {
  border-color: transparent;
  background-color: transparent;
  color: #a1a9ae;
  font-weight: 500;
  text-decoration: underline;
}
.button--color--transparent__gray:hover {
  color: #a1a9ae;
  background-color: #f5f6fa;
}
.button--full-width {
  display: block;
  width: 100%;
}
.button__children {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button--height--narrow {
  padding: 8px 24px;
}
.button--border--black {
  border-color: #000;
}
.button__purchase-top-match {
  text-transform: none;
}
@media (max-width: 480px) {
  .button {
    font-size: 14px;
    padding: 10px 20px;
  }
  .button__purchase-top-match {
    font-size: 14px;
    padding: 16px;
  }
}
.input {
  cursor: pointer;
}
.input-label {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: #576975;
}
.input-label--required {
  color: #e30033;
}
.input-wrapper {
  display: flex;
}
.register-input-wrapper {
  display: flex;
  border: 0.5px solid #000;
  border-radius: 0;
}
.input-wrapper__icon {
  background: #e6e7ec;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
}
.helper-text {
  font-size: 16px;
  color: #616a70;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 8px;
}
.affiliate-helper-small-text {
  font-size: 15px;
}
.affiliate-item-line {
  padding-bottom: 48px;
  border-bottom: 1px solid #c8ccdb;
}
.us-only-disclaimer-text {
  font-size: 12px;
  color: #616a70;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  margin-top: 16px;
}
.input-inner-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  display: block;
  width: 100%;
  color: inherit;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #dde3eb;
  border-radius: 4px;
  padding: 0 20px;
  height: 64px;
  appearance: none;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
textarea::placeholder,
select::placeholder {
  color: #b0b5b8;
}
textarea {
  resize: vertical;
  line-height: 20px;
  height: unset;
}
select {
  background: #fff;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAACNElEQVRYCWNgGAWjITAaAqMhMNJDgBlfACRnF1UampmvNDKz+nD+9PHz+NSiyyVlF4YZmlqsNTSz+H/+9InT6PIwPk4HJGcXNv77/78JqJD3P8N/P2NTy7dAg07BNOKjk3KL4v7/+78IqEYUiL0MzS2+Xzh14ig2PUzYBEFi/xj+JyDJMQL5k5NzCkqQxLAyk3IK0/7//bcAKAn3HON/hlysioGCOB3AzMgSz8jI8AVZ479/DN3AkKlBFkNmJ+UU5AJ9PhMoxogQZ/zBxMCUjuCjsuCuRBVmYDh36tgDE0urg8BoCAXKscPk/zMwOBmbWrACo2MfTAxEA+O89P9/hj5kMaArvjEyM/rNndK/E1kcmY3TASBF504ef2xkbrmHkYExBMjlhGkEOsLOyMyCD+iIXSCxpKyCWqBYG0weRAP1fGZiZvSaO7n/ALI4OhspqNClEPyU/BK9f3/+7AH6EJSo4ABoybT/jP8/MvxnqIQLghiMDB8YGFg85k/tOYkijoVDlANA+lIKyjT//fq9F5gjJLGYgxBiZHjLxMTkOndyH1HZlmgHgGxIzipU+cf4fy/Qx3IIGxEsYKJ9ycjM4jJ3Us8VhCh+Fs5cgE3b3Gn9d5gYmO2AQX8PU57xKQMLmz0ploPMIMkBIA1zp/Y+ZGNnADqC4SaIDwKMjIwPGYFi8yZ2wcUgMoRJkqIA2biU/Crxf3++rwVGBwcHB2PgtL6+x8jyo+zREBgNgdEQGA0BYkMAABRModHRX+wfAAAAAElFTkSuQmCC");
  background-size: 24px 24px;
  background-position: right 16px center;
  padding: 16px 24px;
  cursor: pointer;
}
select::-ms-expand {
  display: none;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: #5057ff;
  box-shadow: 0px 0px 0px 2px rgba(80,87,255,0.5);
}
.input--disabled,
.input--disabled > * {
  cursor: not-allowed !important;
  opacity: 0.65;
}
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="password"]:disabled,
textarea:disabled,
select:disabled {
  background: #f2f2f2;
}
.validation-error {
  background-color: #e30033;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
}
.input-scaffold {
  position: relative;
  display: block;
  width: 100%;
}
.input-scaffold.error input {
  border: solid 2px #e30033;
}
@media (max-width: 480px) {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    height: 48px;
    font-size: 14px;
    padding: 0 16px;
  }
  textarea {
    font-size: 14px;
    padding: 0 16px;
  }
  .input-label {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .input-scaffold {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Mantém o espaço entre os inputs */
  }
}
.checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(0,0,0,0.87);
  font-family: "Inter", "Helvetica Neue", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
.checkbox > input[type="checkbox"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0,0,0,0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}
.checkbox > span {
  display: inline-block;
  width: calc(100% + 18px);
  cursor: pointer;
  padding: 8px;
  margin: -8px;
}
.checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: #576975;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}
.checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 12px;
  height: 6px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}
.checkbox > input[type="checkbox"]:checked,
.checkbox > input[type="checkbox"]:indeterminate {
  background-color: #5057ff;
}
.checkbox > input[type="checkbox"]:checked + span::before,
.checkbox > input[type="checkbox"]:indeterminate + span::before {
  border-color: #5057ff;
  background-color: #5057ff;
}
.checkbox > input[type="checkbox"]:checked + span::after,
.checkbox > input[type="checkbox"]:indeterminate + span::after {
  border-color: #fff;
}
.checkbox > input[type="checkbox"]:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}
.react-toggle .react-toggle-track {
  background-color: #e6e7ec;
  width: 64px;
  height: 32px;
  transition: all 0.25s ease;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #cfcfd4 !important;
}
.react-toggle:hover .react-toggle-track,
.react-toggle:active .react-toggle-track,
.react-toggle:focus .react-toggle-track {
  background-color: #e6e7ec;
}
.react-toggle.react-toggle--checked.react-toggle--focus .react-toggle-track {
  box-shadow: 0px 0px 2px 3px #0099e0;
}
.react-toggle.react-toggle--checked .react-toggle-track {
  background-color: #5057ff;
}
.react-toggle.react-toggle--checked .react-toggle-thumb {
  left: 36px;
}
.react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #484ee5 !important;
}
.react-toggle.react-toggle--checked:hover .react-toggle-track,
.react-toggle.react-toggle--checked:active .react-toggle-track,
.react-toggle.react-toggle--checked:focus .react-toggle-track {
  background-color: #5057ff;
}
.react-toggle .react-toggle-thumb {
  border: 0;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.15);
  background-color: #fff;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 4px;
}
.react-toggle.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}
.toggle--container label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.toggle--container label > * + * {
  margin-left: 16px;
}
.toggle--container .toggle-label {
  color: #2d3e4e;
  font-size: 20px;
  font-weight: 600;
}
.button--toggle__wrapper {
  border: 1px solid #cbcfdb;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: norwrap;
  justify-content: space-between;
  align-items: center;
}
.button--toggle__wrapper .button-toggle {
  border: none;
  flex: 1;
  border-radius: 4px;
  color: #2d3e4e;
}
.button--toggle__wrapper .button-toggle.button--color--primary {
  color: #fff;
}
.file-upload {
  background: rgba(80,87,255,0.05);
  border: 2px dashed #5057ff;
  color: #5057ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 178px;
  font-size: 14px;
  font-weight: 600;
}
.file-upload * {
  margin-bottom: 16px;
}
.kebab-menu {
  margin-right: 48px;
  position: relative;
  width: 24px;
  height: 24px;
}
.kebab-list {
  position: absolute;
  right: 0;
  background-color: #fff;
  padding: 8px 0;
  background: #fff;
  border: 1px solid #dde3eb;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  border-radius: 4px;
  z-index: 10;
}
.kebab-list .menu-item {
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 400;
  color: #505d6f;
  width: 100%;
  min-width: 160px;
  max-width: 180px;
  cursor: pointer;
  color: inherit;
}
.kebab-list .menu-item svg {
  width: 16px;
  height: 16px;
}
.kebab-list .menu-item:hover {
  background-color: #f5f6fa;
}
.kebab-list .menu-item:hover button {
  background-color: #f5f6fa;
}
.modal {
  display: none;
}
.modal.modal--visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99998;
}
@media (max-width: 532px) {
  .modal.modal--visible {
    padding: 16px;
  }
}
@media (max-width: 400px) {
  .modal.modal--visible {
    padding: 0;
  }
}
.modal__children {
  position: relative;
  width: 100%;
  max-width: 700px;
  padding: 32px;
  background: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 99999;
}
.modal__overlay {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: rgba(0,0,0,0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99997;
}
.modal__close {
  display: inline-block;
}
.modal__close svg {
  margin: 4px;
}
.modal--title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.modal--title h1 {
  font-weight: 600;
  text-align: center;
}
.modal--subtext {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #576975;
}
.settings-modal .modal__children {
  max-width: 500px;
}
.settings-modal__body > * + * {
  margin-top: 24px;
}
.modal--button__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #dde3eb;
  padding-top: 24px;
  margin-top: 32px;
}
.modal--button__wrapper > * + * {
  margin-left: 24px;
}
.modal-text {
  line-height: 1.5;
}
@media (max-width: 480px) {
  .modal__children {
    padding: 16px;
  }
  .modal--title h1 {
    font-size: 28px;
  }
}
@media (max-width: 400px) {
  .modal--title h1 {
    font-size: 24px;
  }
}
.user-nav {
  background-color: #fff;
  border-bottom: 1px solid #dde3eb;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.user-nav.co-branding--static__wrapper {
  justify-content: space-between;
}
.user-nav.co-branding--static__wrapper .user-nav__co-brand-kebab {
  padding: 24px;
}
.user-nav__logo--container {
  padding: 24px;
  display: flex;
}
.user-nav__right {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.user-nav__links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.user-nav__links .user-nav__links--link {
  padding: 0 24px;
  width: 100%;
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-bottom: 4px solid transparent;
  white-space: nowrap;
}
.user-nav__co-brand-kebab {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.user-nav__co-brand {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-left: 8px;
}
.user-nav__co-brand > * + * {
  margin-left: 16px;
}
.co-brand--pipe {
  width: 1px;
  height: 32px;
  background-color: rgba(87,105,117,0.5);
  margin-right: 16px;
}
.user-nav__co-brand-logo img {
  max-width: unset;
}
.mobile-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #e6e7ec;
  min-height: 73px;
}
.mobile-nav__flex-full {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 600px) {
  .mobile-nav__flex-full.static-results .user-nav__co-brand-logo img {
    height: 32px;
    min-height: 32px;
  }
}
.mobile-nav .kebab-menu {
  margin-right: 0;
}
.mobile-nav__co-brand {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
}
.mobile-nav__co-brand > * + * {
  margin-left: 16px;
}
@media (max-width: 480px) {
  .mobile-nav__co-brand > * + *,
  .user-nav__co-brand > * + * {
    margin-left: 16px;
  }
  .mobile-nav__co-brand img,
  .user-nav__co-brand img,
  .mobile-nav__co-brand-logo img {
    height: 24px;
    min-height: 24px;
  }
  .co-brand--pipe {
    margin-right: 16px;
  }
}
@media (max-width: 400px) {
  .co-brand--pipe {
    margin-right: 8px;
  }
}
@media (max-width: 360px) {
  .mobile-nav__co-brand > * + *,
  .user-nav__co-brand > * + * {
    margin-left: 8px;
  }
  .co-brand--pipe {
    margin-right: 8px;
  }
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  width: calc(100vw - 64px);
  height: 100vh;
  max-height: none;
  z-index: 10;
  transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1), visibility 0.5s cubic-bezier(0.5, 0, 0.5, 1);
  background: #fff;
  box-shadow: none;
  transform: translateX(calc(-100vw + 64px));
}
.sidebar--header {
  opacity: 0;
  width: 100%;
  padding: 24px;
  transform: translateX(calc(-100vw + 64px));
  transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.5s cubic-bezier(0.5, 0, 0.5, 1);
}
.sidebar-menu {
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateX(calc(-100vw + 64px));
  transition: transform 0.5s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.5s cubic-bezier(0.5, 0, 0.5, 1);
}
.sidebar-menu .user-nav__links--link {
  padding: 24px;
}
.sidebar-menu.sidebar-menu--visible {
  opacity: 1;
  transform: translateX(0);
}
.sidebar-menu .button-gift-mobile {
  max-width: 150px;
  padding: 12px;
  margin-left: 20px;
}
.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  background: rgba(0,0,0,0.6);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.mobile-overlay.mobile-overlay--visible {
  opacity: 1;
  visibility: visible;
}
.sidebar.sidebar--open {
  visibility: visible;
  transform: translateX(0);
}
.sidebar--header.sidebar--header--open {
  opacity: 1;
  transform: translateX(0);
}
.mobile-nav__logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-nav-link {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
@media (max-width: 768px) {
  .user-nav {
    display: none;
  }
  .user-nav__links .user-nav__links--link {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  .mobile-nav {
    display: none;
  }
}
@media (max-width: 400px) {
  .user-nav__co-brand-logo img {
    max-height: 24px;
  }
}
@media (max-width: 360px) {
  .mobile-nav__logo svg {
    width: 100%;
  }
}
.stepper__wrapper {
  width: 100%;
  background-color: #fff;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dde3eb;
}
.stepper--labels {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 950px;
  margin-bottom: 24px;
}
.stepper--step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  position: relative;
  z-index: 6;
  text-align: center;
  font-weight: 500;
  white-space: pre-line;
  margin-bottom: 16px;
  line-height: 1.25;
}
.stepper--navigator {
  width: 100%;
  max-width: 950px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.step-connector {
  justify-self: center;
  align-self: center;
  width: 100%;
  position: relative;
}
.step-connector button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}
.step-connector button.selected .step-circle {
  background-color: #5057ff;
  border-color: #5057ff;
}
.step-connector button.selected .step-circle .selected-dot {
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.step-connector.passed .step-circle {
  background-color: #5057ff;
  border-color: #5057ff;
}
.step-connector.passed .step-circle .selected-dot {
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.step-connector .line {
  height: 4px;
  width: 100%;
  position: absolute;
  z-index: 1;
  right: 0;
  top: calc(50% - 2px);
}
.step-connector .line.first,
.step-connector .line.last {
  width: 50%;
}
.step-connector .line.last {
  left: 0;
}
.step-connector.not-selected .line,
.step-connector.not-passed .line {
  background-color: #616a70;
}
.step-connector.selected .line,
.step-connector.passed .line {
  background-color: #5057ff;
}
.step-circle {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 3px solid #616a70;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
.stepper--navigator__mobile {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px;
}
.stepper--navigator__mobile select {
  border: 0;
  font-size: 18px;
  font-weight: 600;
  color: #5057ff;
  background-color: rgba(80,87,255,0.25);
  height: auto;
  padding: 8px 16px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+QXJ0Ym9hcmQ8L3RpdGxlPgogICAgPGcgaWQ9IkFydGJvYXJkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iY2hldnJvbi1kb3VibGUtZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNS4wMDAwMDAsIDQuMDAwMDAwKSIgZmlsbD0iIzUwNTdGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIHBvaW50cz0iMi4wMDg0IDAuNzI2OTE0Mjg2IDAuMzkyMTYgMi4zNDMxNTQyOSA2Ljg1NzE0Mjg2IDguODA4MTE0MjkgMTMuMzIyMTcxNCAyLjM0MzE4ODU3IDExLjcwNTk0MjkgMC43MjY5NDg1NzEgNi44NTcxNDI4NiA1LjU3NTY4Ij48L3BvbHlnb24+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjAuMzkyMTYgOC44MDgxMTQyOSAyLjAwODQgNy4xOTE4ODU3MSA2Ljg1NzE0Mjg2IDEyLjA0MDY4NTcgMTEuNzA1OTQyOSA3LjE5MTg4NTcxIDEzLjMyMjE3MTQgOC44MDgxMTQyOSA2Ljg1NzE0Mjg2IDE1LjI3MzE0MjkiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
}
@media (max-width: 940px) {
  .stepper--navigator button:before {
    content: unset;
  }
}
@media (max-width: 480px) {
  .stepper__wrapper {
    padding: 16px 0;
  }
  .stepper--labels,
  .stepper--navigator {
    display: none;
  }
  .stepper--navigator__mobile {
    display: flex;
  }
}
.multi-button-field__wrapper .multi-button__header {
  margin-bottom: 24px;
}
.multi-button-field__wrapper .multi-button__header > * + * {
  margin-top: 8px;
}
.multi-button-field__wrapper .mutli-button__title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
}
.multi-button-field__wrapper .multi-button__sublabel {
  font-size: 16px;
  line-height: 1.5;
  color: #616a70;
  font-weight: 300;
}
.multi-button__flex-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  border: 1px solid #e6e7ec;
}
.multi-button__flex-container > * + * {
  border-left: 1px solid #e6e7ec;
}
.multi-button {
  padding: 40px;
  background-color: #fff;
  color: #5057ff;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
}
.multi-button .multi-button__label {
  margin-top: 8px;
}
.multi-button:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.multi-button:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.multi-button:focus {
  outline-color: #5057ff;
}
.multi-button svg {
  fill: currentcolor;
}
.multi-button .check-circle {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
}
.multi-button.active {
  background-color: #5057ff;
  color: #fff;
  font-weight: 600;
}
.multi-button.active .check-circle {
  display: inline-block;
}
.multi-button.active:focus {
  outline-color: #2d3e4e;
  outline-width: 4px;
}
@media (max-width: 600px) {
  .multi-button__flex-container {
    flex-direction: column;
  }
  .multi-button__flex-container > * + * {
    border-left: 0;
    border-top: 1px solid #e6e7ec;
  }
  .multi-button {
    padding: 24px;
  }
}
.slider__wrapper {
  margin-bottom: 96px;
}
.slider__wrapper.text-marks {
  margin-bottom: 112px;
}
.slider--headers {
  margin-bottom: 24px;
}
.slider--headers .h3 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
}
.slider--headers .slider--helper-text {
  font-size: 16px;
  color: #616a70;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
}
.label-tooltip__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}
.label-tooltip__wrapper > span {
  height: 24px;
}
.label-tooltip__wrapper > * + * {
  margin-left: 8px;
}
.slider--value {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 24px;
}
.slider--mark {
  font-size: 20px;
  font-weight: 400;
  color: #616a70;
  line-height: 1.25;
}
.slider--mark.text {
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}
.slider--mark.avg-price {
  transform: translateX(-50%);
}
.rc-slider {
  height: 28px;
  padding: 8px 0;
}
.rc-slider-rail {
  height: 12px;
  border-radius: 2px;
  background-color: #e0e3e8;
}
.rc-slider-track {
  height: 12px;
  border-radius: 2px;
  background-color: #5057ff;
}
.rc-slider-dot {
  opacity: 0;
  visibility: hidden;
}
.rc-slider-handle {
  width: 40px;
  height: 40px;
  margin-top: -14px;
  border: solid 14px #fff;
  background-color: #5057ff;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.25);
  transition: background-color 0.25s ease;
  transition: border-color 0.25s ease;
  backface-visibility: hidden;
}
.rc-slider-handle:hover,
.rc-slider-handle:focus,
.rc-slider-handle:active {
  background-color: #fff;
  border-color: #576975;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.25);
}
.rc-slider-handle:active {
  border-color: #5057ff;
  background-color: #fff;
}
.rc-slider-handle.rc-slider-handle-dragging {
  border-color: #7b81ff !important;
  background-color: #6167ff !important;
  box-shadow: 0 0 0 5px rgba(80,87,255,0.25) !important;
}
.rc-slider-mark {
  margin-top: 40px;
  height: 24px;
  display: flex;
  justify-content: space-between;
}
.rc-slider-step {
  height: 12px;
}
.rc-slider-mark-text {
  transform: unset !important;
  left: unset !important;
  position: static;
}
.slider__inner.dots .rc-slider-dot {
  opacity: 1;
  visibility: visible;
  border: 0;
  background-color: #e0e3e8;
  width: 20px;
  height: 20px;
  bottom: -4px;
  margin-left: -10px;
}
.slider__inner.dots .rc-slider-dot.rc-slider-dot-active {
  background-color: #5057ff;
}
.range__wrapper h3 {
  font-weight: 500;
}
.range__wrapper .subtext {
  font-size: 16px;
  color: #616a70;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  margin-top: 64px;
}
.range--value {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 24px;
}
@media (max-width: 700px) {
  .slider__wrapper {
    padding: 0 16px;
  }
  .slider--headers .h3 {
    font-size: 20px;
  }
  .slider--value {
    font-size: 24px;
  }
  .slider--mark {
    font-size: 16px;
  }
}
.privacy-policy__scrollable {
  background-color: #fff;
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid #dde3eb;
  height: 240px;
  overflow-y: scroll;
}
.privacy-policy__scrollable span.underline {
  text-decoration: underline;
}
.privacy-policy__scrollable h2 {
  font-size: 24px;
  font-weight: 700;
}
.privacy-policy__scrollable h3 {
  font-size: 20px;
}
.privacy-policy__scrollable p {
  margin-top: 8px;
}
.privacy-policy__scrollable p.emphasis {
  font-weight: 500;
}
.privacy-policy__scrollable address {
  font-weight: 700;
}
.privacy-policy__scrollable ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.privacy-policy__scrollable ul > * + * {
  padding-top: 8px;
}
.privacy-policy__scrollable li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.privacy-policy--section__wrapper > * + * {
  margin-top: 24px;
}
.tooltip-icon {
  cursor: pointer;
}
.tooltip-body {
  width: 300px;
  overflow-wrap: break-word;
  display: block;
  line-height: 1.5;
  padding: 4px 0;
  font-size: 14px;
}
.something-went-wrong--page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.something-went-wrong--page .header {
  background-color: #fff;
  width: 100%;
  padding: 36px 16px;
  border-bottom: 1px solid #dde3eb;
}
.something-went-wrong--page h1 {
  font-size: 48px;
  font-weight: 700;
  margin: 24px 0;
}
.something-went-wrong--page .content {
  max-width: 1020px;
  padding: 72px 24px 24px;
}
.something-went-wrong--page .content svg {
  width: 96px;
  height: 96px;
}
.something-went-wrong--page .content p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 48px;
}
@media (max-width: 480px) {
  .something-went-wrong--page h1 {
    font-size: 32px;
  }
  .something-went-wrong--page .content svg {
    width: 48px;
    height: 48px;
  }
}
.pager-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.pager-container .disabled {
  opacity: 0.5;
}
.previous-button,
.next-button {
  font-size: 18px;
  font-weight: 500;
}
.previous-button {
  margin-right: auto;
}
.next-button {
  margin-left: auto;
}
.page-number {
  border-bottom: 4px solid transparent;
}
.page-number.active-page {
  border-bottom-color: #5057ff;
}
.page-number.active-page .page-link {
  color: #5057ff;
}
.page-number .page-link {
  color: #7a848b;
}
.page-number,
.page-break {
  font-size: 22px;
  font-weight: 600;
  padding: 0 16px 8px;
  margin: 0 8px;
}
.page-break a {
  color: #7a848b;
}
.page-number,
.page-break,
.previous-button,
.next-button {
  cursor: pointer;
}
.previous-label,
.next-label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.pagination-td,
.pagination-div {
  padding: 40px 24px 16px !important;
}
@media (max-width: 768px) {
  .page-number,
  .page-break {
    font-size: 16px;
    font-weight: 600;
    padding: 0 8px 4px;
    margin: 0 4px;
  }
  .previous-button,
  .next-button {
    font-size: 14px;
    font-weight: 500;
  }
}
@media (max-width: 520px) {
  .previous-label .label-text,
  .next-label .label-text {
    display: none;
  }
}
.content--right {
  width: 500px !important;
}
.content--right .carousel__inner-slide img {
  height: unset !important;
}
.how-it-works {
  background-color: #fff;
}
.whats-included {
  background-color: #f5f6fa;
  padding: 96px 96px;
}
.how-it-works .whats-included h1,
.whats-included h1 {
  color: #000;
  font-size: 32px;
  margin-top: 60px;
}
.how-it-works .whats-included p,
.whats-included p {
  color: #000;
  text-align: left;
  font-size: 14px;
}
.how-it-works .whats-included img,
.whats-included img {
  max-width: 100%; /* Allows the image to scale down */
  height: auto; /* Maintains aspect ratio */
  width: auto; /* Allows the width to be responsive */
  max-height: 150px; /* Maximum height to not exceed */
}
.guesswork {
  background-color: #f5f6fa;
  padding-top: 96px;
}
.how-it-works .guesswork h1,
.guesswork h1 {
  color: #000;
  font-size: 32px;
  margin-top: 60px;
}
.how-it-works .guesswork p,
.guesswork p {
  color: #000;
  text-align: left;
  font-size: 14px;
}
.how-it-works .guesswork img,
.guesswork img {
  max-width: 100%; /* Allows the image to scale down */
  height: auto; /* Maintains aspect ratio */
  width: auto; /* Allows the width to be responsive */
  max-height: 150px; /* Maximum height to not exceed */
}
.how-it-works .closer-look-container h1,
.closer-look-container h1 {
  color: #000;
  font-size: 32px;
  margin-top: 60px;
}
.how-it-works .closer-look-container p,
.closer-look-container p {
  color: #000;
  text-align: left;
  font-size: 14px;
}
.closer-look-video {
  width: 50%;
  height: 315px;
}
.top-matches {
  background-color: #fff;
  padding: 96px 96px;
}
.how-it-works .top-matches h1,
.top-matches h1 {
  color: #000;
  font-size: 32px;
  margin-bottom: 0px;
  padding: 20px 0 20px;
}
.how-it-works .top-matches p,
.top-matches p {
  text-align: left;
  font-size: 14px;
}
.how-it-works .top-matches img,
.top-matches img {
  max-width: 100%; /* Allows the image to scale down */
  height: auto; /* Maintains aspect ratio */
  width: auto; /* Allows the width to be responsive */
  max-height: 150px; /* Maximum height to not exceed */
}
.how-it-works .top-matches h3,
.top-matches h3 {
  color: #000;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
  padding: 20px 0 20px;
}
@media (max-width: 1023px) {
  .header-with-video {
    height: 450px;
    width: 100%;
    background-color: #00f;
    margin-bottom: 500px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.header-with-video {
  height: 450px;
  width: 100%;
  background-color: #000;
  margin-bottom: 500px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-startlifting {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 100px;
}
.header-with-video iframe {
  overflow: hidden;
}
.header-with-video h1 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 100px;
}
.images-row {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente, se necessário */
  gap: 30px;
  align-content: space-between;
  padding: 20px 0; /* Adds padding at the top and bottom */
  margin-top: 100px;
  margin-bottom: 100px;
}
.images-row-guesswork {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente, se necessário */
  gap: 30px;
  align-content: space-between;
  padding: 20px 0; /* Adds padding at the top and bottom */
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #fff;
}
.images-row-top-matches {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente, se necessário */
  gap: 24px;
  padding: 20px 0; /* Adds padding at the top and bottom */
  margin-top: 30px;
  margin-bottom: 100px;
}
.review-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  width: 15%;
  gap: 40%;
  height: 140px;
  margin-top: 50px;
}
.review-images1 {
  width: 100px; /* Defina a largura desejada */
  height: auto; /* Mantém a proporção da imagem */
}
.review-images2 {
  margin-top: 30px;
  width: auto; /* Defina a largura desejada */
  height: 70px; /* Mantém a proporção da imagem */
}
.review-images3 {
  margin-top: 30px;
  width: auto; /* Defina a largura desejada */
  height: 70px; /* Mantém a proporção da imagem */
}
.review-images4 {
  margin-top: 30px;
  width: auto; /* Defina a largura desejada */
  height: 70px; /* Mantém a proporção da imagem */
}
.images-column-text1 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza verticalmente, se necessário */
  gap: 35px; /* Adjust gap to a reasonable size for spacing */
  padding: 30px 30px 30px 30px; /* Adds padding at the top and bottom */
  border-radius: 10px;
  background-color: #fff;
}
.images-column-text1 p {
  font-size: 14px;
  line-height: 1.4;
}
.images-column-text1 h2 {
  font-size: 20px;
}
.text-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px; /* Adjust gap to a reasonable size for spacing */
  padding: 5px 0px 5px 0px; /* Adds padding at the top and bottom */
  margin-top: 10px;
  text-align: center;
}
.quotes-session {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fff;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o container */
  align-items: center; /* Centraliza verticalmente o container */
  text-align: center;
}
.quotes-container {
  display: flex;
  align-items: center; /* Centraliza verticalmente as aspas e o texto */
}
.quotes-session-text {
  padding: 0 40px; /* Adiciona espaçamento entre o texto e as aspas */
}
.quote-mark1 {
  width: 40px; /* Ajuste o tamanho da imagem da aspa se necessário */
  opacity: 1; /* Adiciona transparência às aspas */
  margin-bottom: 50px;
}
.quote-mark2 {
  width: 40px; /* Ajuste o tamanho da imagem da aspa se necessário */
  opacity: 1; /* Adiciona transparência às aspas */
  margin-top: 35px;
}
.quotes-session h3 {
  font-size: 18px;
  font-family: "Misc/Eyebrow Extended";
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 30px;
}
.quotes-session h4 {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}
.text-column p {
  font-size: 18px;
}
.text-column h2 {
  font-size: 20px;
}
.text-column h4 {
  color: #c29225;
  font-size: 18px;
  border: 1px solid #c29225;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
}
.images-column-text-left {
  display: flex;
  gap: 100px;
  padding: 20px; /* Adds padding at the top and bottom */
  margin-bottom: 30px;
  margin-top: 30px;
}
.images-column-text-left p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 15px;
}
.images-column-and-text {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza verticalmente, se necessário */
  gap: 50px; /* Adjust gap to a reasonable size for spacing */
  padding: 30px 30px 30px 30px; /* Adds padding at the top and bottom */
  border-radius: 10px;
  background-color: #fff;
}
.images-column-text {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza verticalmente, se necessário */
  gap: 35px; /* Adjust gap to a reasonable size for spacing */
}
.images-column-text h2 {
  font-size: 20px;
}
.images-column-text p {
  font-size: 14px;
  line-height: 1.4;
}
.text-column-row {
  background-color: #fff;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza horizontalmente */
  align-content: center;
  border-radius: 15px;
  padding: 70px 70px 70px 70px; /* Adds padding at the top and bottom */
  margin-top: 100px;
  margin-bottom: 100px;
}
.text-column-row h2 {
  background-color: #fff;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente, se necessário */
  align-content: center;
  padding-bottom: 20px;
}
.text-column-row p {
  text-align: left;
}
.top-matches-column {
  border: #000;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza verticalmente, se necessário */
  padding: 24px 50px 24px 50px; /* Adds padding at the top and bottom */
  border-radius: 10px;
  background: linear-gradient(to bottom, #d8ddf3 50%, #f5f6fa 50%);
  box-shadow: 0 12px 12px rgba(0,0,0,0.3);
}
.top-matches-column img {
  box-shadow: 0px 10px 15px rgba(0,0,0,0.4);
  border-radius: 50%;
}
.top-matches-column h2 {
  font-size: 20px;
  margin-top: 15px;
}
.top-matches-column h4 {
  font-size: 32px;
}
.top-matches-column p {
  border-radius: 17px;
  padding: 6px 15px 6px 15px;
  background-color: #444ada;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 1.2;
  color: #fff;
}
.review-container {
  padding-top: 140px;
  padding-bottom: 140px;
  background-color: #f5f6fa;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.review-container h2 {
  font-family: "Misc/Eyebrow Extended";
  font-size: 20px;
  font-weight: 400;
}
@media (max-width: 800px) {
  .how-it-works .guesswork img,
  .guesswork img {
    max-height: 110px; /* Smaller maximum height for smaller screens */
  }
  .how-it-works .guesswork h1,
  .guesswork h1 {
    font-size: 32px;
    margin-bottom: 0px;
    padding: 20px 0 20px;
  }
  .how-it-works .guesswork p,
  .guesswork p {
    text-align: center;
    font-size: 16px;
  }
  .images-row {
    gap: 80px;
  }
  .guesswork {
    height: 600px;
  }
}
@media (max-width: 600px) {
  .how-it-works .guesswork img,
  .guesswork img {
    max-height: 80px; /* Smaller maximum height for smaller screens */
  }
  .images-row {
    gap: 10px;
  }
}
.quote--carousel {
  width: 100%;
  margin: 0 auto;
}
.quote--carousel .how-it-works--slide {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 32px;
}
.quote--carousel .how-it-works--slide > * + * {
  margin-left: 40px;
}
.quote--carousel .how-it-works--slide img {
  width: 600px;
  height: auto;
  box-shadow: 0 12px 12px rgba(0,0,0,0.2);
}
.quote--carousel .carousel .slide {
  background-color: transparent;
}
.quote--carousel .carousel .control-dots {
  position: relative;
  margin-top: 40px;
  margin-bottom: 100px;
}
.quote--carousel .carousel .control-dots > * + * {
  margin-left: 16px;
}
.quote--carousel .carousel .control-dots .dot {
  width: 16px;
  height: 16px;
  box-shadow: none;
  background-color: #a1a9ae;
  opacity: 1;
  margin-right: 0;
}
.quote--carousel .carousel .control-dots .dot.selected {
  background-color: #5057ff;
}
.how-it-works--carousel {
  width: 100%;
  margin: 0 auto;
}
.how-it-works--carousel .how-it-works--slide {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 32px;
}
.how-it-works--carousel .how-it-works--slide > * + * {
  margin-left: 40px;
}
.how-it-works--carousel .how-it-works--slide img {
  width: 600px;
  height: auto;
  box-shadow: 0 12px 12px rgba(0,0,0,0.2);
}
.how-it-works--carousel .carousel .slide {
  background-color: transparent;
}
.how-it-works--carousel .carousel .control-dots {
  position: relative;
  margin-top: 24px;
}
.how-it-works--carousel .carousel .control-dots > * + * {
  margin-left: 48px;
}
.how-it-works--carousel .carousel .control-dots .dot {
  width: 16px;
  height: 16px;
  box-shadow: none;
  background-color: #a1a9ae;
  opacity: 1;
  margin-right: 0;
}
.how-it-works--carousel .carousel .control-dots .dot.selected {
  background-color: #5057ff;
}
.how-it-works--carousel .carousel .control-arrow {
  bottom: unset;
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: #5057ff;
  opacity: 1;
  text-align: center;
  vertical-align: middle;
  padding: 0;
}
.how-it-works--carousel .carousel .control-arrow:hover {
  background-color: #2f37ff;
}
.how-it-works--carousel .carousel .control-arrow.control-next:before {
  border: 0;
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAUCAYAAABWMrcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABMSURBVHgB7ctLCQAgEIThjWAEm2kTq9jICEYZV1jwcRoRb/4wt/lELABB54XNQK/SUI8FIw7qyX34CNYZMihhLX5wDQzlI7BBHpzWADjBVpfp3zSFAAAAAElFTkSuQmCC");
  margin: 0;
}
.how-it-works--carousel .carousel .control-arrow.control-prev:before {
  border: 0;
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAUCAYAAABWMrcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABSSURBVHgB7dFBCQAgEETRiWAEI2hDGxjRCEZYV1AQ8TAsnsQPc3wiLEAkIl4XwDZA0VUKLmCWPrgB3AYymPrLC+KO+ADUmWC0QtNXE9gG5MGpBjW9RaQbB4/LAAAAAElFTkSuQmCC");
  margin: 0;
}
.quote-slide--text {
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 120px;
}
.quote-slide--text h3 {
  color: #5057ff;
  font-size: 24px;
}
.quote-slide--text h2 {
  font-size: 28px;
  margin-bottom: 16px;
}
.quote-slide--text p {
  font-style: italic;
  font-size: 28px;
  font-weight: semibold;
  line-height: 30px;
  margin-bottom: 16px;
  color: #000;
}
.slide-info--text {
  width: 100%;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.slide-info--text h3 {
  color: #5057ff;
}
.slide-info--text h2 {
  font-size: 20px;
  margin-bottom: 16px;
}
.slide-info--text p {
  font-size: 18px;
  line-height: 30px;
}
.disclaimer-carousel {
  margin-top: 40px;
}
.disclaimer-carousel .carousel .control-arrow,
.disclaimer-carousel .carousel.carousel-slider .control-arrow {
  opacity: 1;
}
.disclaimer-carousel .carousel .control-arrow:before,
.disclaimer-carousel .carousel.carousel-slider .control-arrow:before {
  margin: 0;
  position: absolute;
  top: 16px;
  bottom: 16px;
  left: 20px;
  right: 20px;
}
.disclaimer-carousel .carousel.carousel-slider .control-arrow {
  top: calc(50% - 16px);
  bottom: unset;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #5057ff;
}
.disclaimer-carousel .carousel .slide {
  background-color: transparent;
}
.disclaimer-carousel .carousel .control-dots {
  bottom-margin: 50px;
  position: relative;
  bottom: unset;
}
.disclaimer-carousel .carousel .control-dots .dot {
  box-shadow: none;
  opacity: 1;
  background: #a1a9ae;
}
.disclaimer-carousel .carousel .control-dots .dot.selected {
  background: #5057ff;
}
@media (max-width: 1160px) {
  .how-it-works--carousel .how-it-works--slide {
    flex-direction: column;
  }
  .how-it-works--carousel .how-it-works--slide > * + * {
    margin-left: 0;
    margin-top: 24px;
  }
  .how-it-works--carousel .carousel .control-dots {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .slide-info--text {
    max-width: 900px;
  }
  .slide-info--text h2 {
    font-size: 24px;
  }
  .slide-info--text p {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media (max-width: 1024px) {
  .how-it-works--carousel .how-it-works--slide img {
    width: 400px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
  }
}
@media (max-width: 768px) {
  .quote-slide--text p {
    font-size: 20px;
  }
  .quote-slide--text h3 {
    font-size: 20px;
  }
  .quote--carousel .carousel .control-dots {
    position: relative;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .quote--carousel .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
  }
  .how-it-works--carousel .how-it-works--slide {
    padding: 8px;
  }
  .how-it-works--carousel .carousel .control-dots > * + * {
    margin-left: 16px;
  }
  .how-it-works--carousel .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
  }
  .how-it-works--carousel .carousel .control-arrow {
    width: 32px;
    height: 32px;
    bottom: 0;
    top: unset;
  }
  .how-it-works--carousel .carousel .control-arrow.control-next:before {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAACXBIWXMAAAhOAAAITgGMMQDsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABPSURBVHgB3dAxDQAgDETRSkACknCAFRwiAQlIOEoogTC0CwPhJ91eOhwRB8DzBdJi4PgKRtHCCau/sMxVBWYNlYn6fA8gZ6JjDh3tn+lGDdVk0+uYqbIcAAAAAElFTkSuQmCC");
  }
  .how-it-works--carousel .carousel .control-arrow.control-prev:before {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAACXBIWXMAAAhOAAAITgGMMQDsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABbSURBVHgB1Y9BDcAgEAT3qqASkFALVVIplVAJtVQHDQqQcGwCDx6Egx9MMrnPZJMDGqjqBQtGryaenuinbqXI0WB+mOOjiO9JYjq2vNVCEfl4ThqohwXXdowSAUvat6wqaBkeAAAAAElFTkSuQmCC");
  }
}
@media (max-width: 600px) {
  .content--right {
    width: 100% !important;
    max-width: 500px !important;
  }
  .slide-info--text {
    max-width: 900px;
  }
  .slide-info--text h2 {
    font-size: 20px;
  }
  .slide-info--text p {
    font-size: 14px;
  }
}
@media (max-width: 660px) {
  .guesswork {
    height: 600px;
  }
}
@media (max-width: 480px) {
  .guesswork {
    height: 460px;
    margin-bottom: 0px;
  }
  .quote-slide--text {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .quote-slide--text h3 {
    color: #5057ff;
    font-size: 20px;
    padding-bottom: 5px;
  }
  .quote-slide--text h2 {
    font-size: 28px;
    margin-bottom: 16px;
  }
  .quote-slide--text p {
    font-style: italic;
    font-size: 14px;
    font-weight: semibold;
    line-height: 30px;
    margin-bottom: 16px;
    color: #000;
  }
  .how-it-works--carousel .how-it-works--slide {
    flex-direction: column;
  }
  .how-it-works--carousel .how-it-works--slide > * + * {
    margin-left: 0;
    margin-top: 24px;
  }
  .how-it-works--carousel .carousel .control-dots {
    margin-top: 40px;
    margin-bottom: $0px;
  }
}
.brand-toggling-wrapper {
  display: flex;
  justify-content: center;
}
.brand-toggling {
  background-color: #fff;
  padding: 24px 200px 24px 60px;
  min-width: 800px;
}
.global-settings-h1 {
  font-weight: 600;
}
.iron-toggling-helper {
  font-weight: 700;
  font-size: 15px;
}
.brands-wrapper {
  margin-top: 50px;
}
.brand {
  display: grid;
  grid-template-columns: 300px 300px;
  column-gap: 50px;
}
.brands-settings-save-button {
  text-align: right;
  padding: 20px 0px;
  margin-right: 100px;
}
.questionnaire-confirm-inputs-section {
  padding: 20px;
  text-align: left;
}
.questionnaire-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.questionnaire-edit-button {
  background: none;
  border: none;
  color: #00f;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}
.questionnaire-section-content {
  margin-bottom: 30px;
}
.questionnaire-detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.questionnaire-profile,
.questionnaire-driver-details,
.questionnaire-iron-details,
.questionnaire-wedge-details,
.questionnaire-finishing-touches {
  display: grid;
  gap: 10px;
}
.questionnaire-h1 {
  text-align: center;
  margin-bottom: 30px;
}
.questionnaire-h2 {
  font-size: 1.2rem;
  margin: 0;
}
.questionnaire-icon-centered {
  display: flex;
  justify-content: center;
}
.questionnaire-centered-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.admin-header {
  background-color: #fff;
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid #e6e7ec;
}
.admin-header .admin-header__inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.admin-header .admin-logo__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.admin-header .admin-logo__container > * + * {
  margin-left: 24px;
}
.admin-header .admin-logo__container .admin-tag {
  font-size: 18px;
  font-weight: 700;
  color: #a1a9ae;
  margin-top: 8px;
}
.admin-header .logout-link {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
}
.admin-header__nav {
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #e6e7ec;
}
.admin-header__nav .nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.admin-header__nav .admin-header__nav-icon {
  color: #576975;
  font-size: 16px;
  height: 100%;
  padding: 24px 0;
  margin-left: 56px;
  border-bottom: 4px solid transparent;
}
.admin-header__nav .admin-header__nav-icon:first-child {
  margin-left: 16px;
}
.admin-header__nav .admin-header__nav-icon.active {
  color: #5057ff;
  border-bottom: 4px solid #5057ff;
}
@media (max-width: 768px) {
  .admin-header__nav {
    height: auto;
    padding: 16px 0;
  }
  .admin-header__nav .nav-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .admin-header__nav .admin-header__nav-icon {
    width: 100%;
    margin: 0;
    padding: 16px 32px;
    border-bottom: none;
    border-left: 4px solid transparent;
  }
  .admin-header__nav .admin-header__nav-icon.active {
    border-bottom: none;
    border-left: 4px solid #5057ff;
  }
  .admin-header__nav .admin-header__nav-icon:hover {
    background-color: rgba(0,0,0,0.05);
  }
}
.add-user-field--form__wrapper {
  width: 100%;
  max-width: 480px;
}
.invite-user-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.invite-user-form__container {
  width: 100%;
  margin: 40px auto;
}
.invite-user-form__container h1 {
  margin-bottom: 40px;
}
.invite-user-form__container .add-user--email {
  padding: 16px 20px;
}
.add-user-field__container {
  width: 100%;
}
.add-user-field__container > * + * {
  margin-top: 8px;
}
.add-user-field__container .add-user-field__container--flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}
.add-user-field__container .add-user-field__container--flex .input-scaffold {
  flex: 0 1 auto;
}
.add-user-field__container .add-user-field__container--flex input {
  height: 56px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.add-user-field__container .add-user-field__container--flex .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.add-user--actions-bar__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
}
.add-user--actions-bar__wrapper .actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.add-user--actions-bar__wrapper .actions > * + * {
  margin-left: 24px;
}
.fitter-list {
  width: 100%;
  margin-top: 40px;
}
.fitter-list > * + * {
  border-top: 1px solid #c8ccdb;
}
.fitter-list th {
  text-align: left;
  padding: 0 16px 16px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #576975;
}
.fitter-list tbody {
  border: 1px solid #e6e7ec;
  background-color: #fff;
}
.fitter-list tbody > * + * {
  border-top: 1px solid #e6e7ec;
}
.fitter-list td {
  padding: 24px 16px;
  font-size: 14px;
  font-weight: 500;
}
.fitter-entry {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}
.fitter-entry--email {
  width: 100%;
  max-width: 220px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #616a70;
  padding: 8px 0;
}
.fitter-status__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.fitter-entry--status {
  font-size: 12px;
  font-weight: 700;
  padding: 4px;
  text-transform: uppercase;
  border-radius: 4px;
}
.fitter-entry--status.verified {
  background-color: rgba(36,130,39,0.25);
  color: #248227;
}
.fitter-entry--status.unverified {
  background-color: rgba(255,143,72,0.25);
  color: #ff8f48;
}
.tooltip-form-table__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.tooltip-form-table__wrapper > * + * {
  margin-left: 4px;
}
.tooltip-form-table__wrapper .truncated-string {
  white-space: nowrap;
}
.tooltip-form-table__wrapper .add-button {
  opacity: 0.75;
  font-weight: 500;
  white-space: nowrap;
}
.delete-button {
  position: relative;
}
.download-csv-button {
  text-transform: none;
}
.admin-user-button__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.admin-user-button__icon-wrapper.delete {
  background-color: #f95d5d;
}
.admin-user-button__icon-wrapper.delete svg path {
  fill: #fff;
}
.admin-user-button__icon-wrapper.delete:hover {
  background-color: #f84d4d;
}
.admin-user-button__icon-wrapper.delete:hover svg path {
  fill: #fff;
}
.admin-user-button__icon-wrapper.refresh {
  background-color: #5057ff;
}
.admin-user-button__icon-wrapper.refresh svg path {
  fill: #fff;
}
.admin-user-button__icon-wrapper.refresh:hover {
  background-color: #3f47ff;
}
.admin-user-button__icon-wrapper.refresh:hover svg path {
  fill: #fff;
}
.admin-user-button__icon-wrapper svg path {
  transition: 0.25s ease;
}
.individual-user-action-buttons__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.individual-user-action-buttons__wrapper > * + * {
  margin-left: 8px;
}
.confirmation-tooltip {
  position: absolute;
  width: 180px;
  right: -70px;
  top: 56px;
  background: #fff;
  padding: 16px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.09), 0 1px 5px 0 rgba(0,0,0,0.12);
  border-radius: 4px;
  z-index: 5;
}
.confirmation-tooltip .confirmation-tooltip--header {
  font-size: 14px;
  font-weight: 500;
  color: #505d6f;
  margin-bottom: 16px;
}
.confirmation-tooltip .button--wrapper {
  padding-top: 16px;
  border-top: 1px solid #dde3eb;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.confirmation-tooltip .button--wrapper > * + * {
  margin-left: 16px;
}
.confirmation-tooltip .button--wrapper .faux-link {
  font-size: 14px;
}
.confirmation-tooltip:before,
.confirmation-tooltip:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.confirmation-tooltip:before {
  border-color: transparent;
  border-bottom-color: #e0e2eb;
  border-width: 9px;
  margin-left: -9px;
}
.confirmation-tooltip:after {
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.note-preview {
  background-color: #2d3e4e;
  border-radius: 4px;
}
.note-preview .tippy-content {
  padding: 16px;
  background-color: #2d3e4e;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 2px 8px rgba(0,34,78,0.2);
  border-radius: 4px;
}
.note-preview .tippy-arrow {
  color: #2d3e4e;
  box-shadow: 0px 2px 8px rgba(0,34,78,0.2);
}
.tooltip {
  position: relative;
}
.tooltip .show-tooltip {
  position: absolute;
  width: 400px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0,34,78,0.2);
  border-radius: 4px;
  z-index: 10;
}
.tooltip .show-tooltip:after,
.tooltip .show-tooltip:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.tooltip .show-tooltip:after {
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 12px;
  margin-left: -12px;
}
.tooltip .show-tooltip:before {
  border-color: transparent;
  border-bottom-color: #e6e7ec;
  border-width: 13px;
  margin-left: -13px;
}
.tooltip .tooltip-form textarea {
  padding: 12px 16px;
  font-size: 14px;
}
.tooltip .tooltip-form input[type="text"] {
  font-size: 14px;
  height: 48px;
}
.tooltip .tooltip-form .input-label {
  color: rgba(97,106,112,0.6);
  text-transform: none;
}
.tooltip .tooltip-form .button {
  font-size: 14px;
  padding: 12px 16px;
}
.tooltip .tooltip-button__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #e6e7ec;
}
.tooltip .tooltip-button__wrapper > * + * {
  margin-left: 16px;
}
.co-brands-header__container {
  width: 100%;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.co-brands__empty {
  width: 443px;
  margin: 0 auto;
  text-align: center;
}
.co-brands__empty * {
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .invite-user-form__container {
    margin: 40px auto;
    padding: 0 16px;
  }
}
@media (max-width: 680px) {
  .confirmation-tooltip {
    width: 180px;
    left: -196px;
    top: calc(50% - 46px);
  }
  .confirmation-tooltip:before,
  .confirmation-tooltip:after {
    bottom: unset;
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-left: unset;
  }
  .confirmation-tooltip:before {
    border-color: rgba(224,226,235,0);
    border-left-color: #e0e2eb;
    border-width: 9px;
    margin-top: -9px;
  }
  .confirmation-tooltip:after {
    border-color: rgba(255,255,255,0);
    border-left-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }
}
@media (max-width: 580px) {
  .admin-header {
    padding: 24px 0;
  }
  .admin-header .admin-header__inner {
    justify-content: space-between;
  }
  .admin-header .logout-link {
    position: relative;
    top: unset;
    right: unset;
    font-size: 14px;
  }
  .admin-header .admin-logo__container > * + * {
    margin-left: 16px;
  }
  .admin-header .admin-logo__container .admin-tag {
    font-size: 16px;
  }
  .admin-logo__container svg {
    width: 180px;
    height: 42px;
  }
}
@media (max-width: 520px) {
  .fitter-entry--email {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .invite-user-form__container {
    margin: 24px auto;
  }
  .fitter-entry {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding: 24px;
  }
  .fitter-entry > * + * {
    margin-top: 16px;
  }
  .delete-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 24px;
    margin-right: 16px;
  }
  .add-user-field__container .add-user-field__container--flex input {
    height: 48px;
  }
}
@media (max-width: 400px) {
  .fitter-entry {
    padding: 16px;
  }
  .add-user-field__container .add-user-field__container--flex {
    flex-direction: column;
  }
  .add-user-field__container .add-user-field__container--flex .button {
    display: block;
    width: 100%;
    margin-top: 16px;
  }
}
@media (max-width: 360px) {
  .admin-header .admin-logo__container > * + * {
    margin-left: 8px;
  }
  .admin-header .admin-logo__container .admin-tag {
    font-size: 14px;
  }
  .admin-logo__container svg {
    width: 168px;
    height: 40px;
  }
}
@media (max-width: 768px) {
  .admin-header {
    padding: 16px 0;
  }
  .admin-header .admin-header__inner {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .admin-header .admin-logo__container {
    width: 100%;
    justify-content: space-between;
  }
  .admin-header .admin-logo__container .admin-tag {
    margin-left: 16px;
  }
  .admin-header .logout-link {
    width: 100%;
    text-align: right;
    padding: 0 16px;
  }
}
@media (max-width: 768px) {
  .add-user--actions-bar__wrapper {
    flex-direction: column;
    gap: 24px;
  }
  .add-user--actions-bar__wrapper .actions {
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
  }
  .add-user--actions-bar__wrapper .actions > * {
    margin-left: 0 !important;
  }
}
.admin-dashboard {
  padding: 24px;
  background-color: #f8f9fa;
  min-height: calc(100vh - 136px);
}
.admin-dashboard .admin-dashboard__table-title-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.admin-dashboard .admin-dashboard__table-stats {
  font-size: 0.9em;
  color: #666;
  margin-top: -4px;
}
.admin-dashboard .admin-dashboard__table-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}
.admin-dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.admin-dashboard__title {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  color: #2d3e4e;
}
.admin-dashboard__actions {
  display: flex;
  gap: 12px;
}
.admin-dashboard__refresh-button {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px 18px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}
.admin-dashboard__refresh-button:hover {
  background-color: #f5f5f5;
  border-color: #d0d0d0;
}
.admin-dashboard__refresh-button:active {
  background-color: #e9e9e9;
}
.admin-dashboard__refresh-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.admin-dashboard__last-updated {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 12px;
  margin-top: 24px;
  font-style: italic;
}
.admin-dashboard__error {
  background-color: #fff5f5;
  border: 1px solid #fcc;
  color: #e53e3e;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 24px;
  font-weight: 500;
}
.admin-dashboard__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  color: #6c757d;
}
.admin-dashboard__loading-indicator {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}
.admin-dashboard__stats {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  margin-bottom: 36px;
}
.admin-dashboard__stat-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  padding: 24px;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid rgba(0,0,0,0.03);
}
.admin-dashboard__stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}
.admin-dashboard__stat-title {
  font-size: 15px;
  color: #6c757d;
  margin-bottom: 12px;
  font-weight: 500;
}
.admin-dashboard__stat-value {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #2d3e4e;
}
.admin-dashboard__stat-details {
  font-size: 13px;
  color: #6c757d;
  margin-top: auto;
  line-height: 1.5;
}
.admin-dashboard__stat-details div {
  margin-bottom: 4px;
}
.admin-dashboard__tables {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}
@media (min-width: 1200px) {
  .admin-dashboard__tables {
    grid-template-columns: 1fr 1fr;
  }
}
.admin-dashboard__table-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  padding: 24px;
  border: 1px solid rgba(0,0,0,0.03);
}
.admin-dashboard__table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}
.admin-dashboard__table-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #2d3e4e;
  display: flex;
  align-items: center;
  gap: 8px;
}
.admin-dashboard__filter-indicator {
  font-size: 13px;
  color: #6c757d;
  font-weight: normal;
}
.admin-dashboard__view-all {
  font-size: 14px;
  color: #5057ff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}
.admin-dashboard__view-all:hover {
  text-decoration: underline;
  color: #2f37ff;
}
.admin-dashboard__table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.admin-dashboard__table th,
.admin-dashboard__table td {
  padding: 12px 16px;
  text-align: left;
}
.admin-dashboard__table th {
  font-weight: 600;
  color: #495057;
  font-size: 14px;
  border-bottom: 2px solid #f0f0f0;
  white-space: nowrap;
}
.admin-dashboard__table td {
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
  color: #495057;
}
.admin-dashboard__table tr:last-child td {
  border-bottom: none;
}
.admin-dashboard__table tbody tr {
  transition: background-color 0.2s ease;
}
.admin-dashboard__table tbody tr:hover {
  background-color: #f8f9fa;
}
.admin-dashboard__table tbody tr:nth-child(even) {
  background-color: #fcfcfc;
}
.admin-dashboard__table tbody tr:nth-child(even):hover {
  background-color: #f8f9fa;
}
.admin-dashboard__no-data {
  padding: 40px 0;
  text-align: center;
  color: #6c757d;
  font-style: italic;
  background-color: #fcfcfc;
  border-radius: 6px;
}
.admin-dashboard__view-user {
  color: #5057ff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
.admin-dashboard__view-user:hover {
  text-decoration: underline;
}
.admin-dashboard__date-filter {
  margin: 0 0 24px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  border: 1px solid rgba(0,0,0,0.03);
}
.admin-dashboard__date-filter-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.admin-dashboard__date-filter-label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
  color: #2d3e4e;
  display: flex;
  align-items: center;
  gap: 8px;
}
.admin-dashboard__date-filter-badge {
  display: inline-block;
  padding: 4px 8px;
  background-color: #e6f4ff;
  color: #06c;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}
.admin-dashboard__date-filter-help {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 16px;
}
.admin-dashboard__date-filter-inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 16px;
}
.admin-dashboard__date-picker-container {
  display: flex;
  flex-direction: column;
  min-width: 180px;
}
.admin-dashboard__date-picker-container label {
  font-size: 14px;
  margin-bottom: 6px;
  color: #495057;
  font-weight: 500;
}
.admin-dashboard__date-picker-wrapper {
  position: relative;
  width: 100%;
}
.admin-dashboard__date-picker {
  padding: 10px 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
}
.admin-dashboard__date-picker:focus {
  outline: none;
  border-color: #5057ff;
  box-shadow: 0 0 0 3px rgba(80,87,255,0.15);
}
.admin-dashboard__date-picker:hover:not(:disabled) {
  border-color: #b0b0b0;
}
.admin-dashboard__date-picker:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}
.admin-dashboard__day-picker-overlay {
  position: absolute;
  z-index: 10;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 16px rgba(0,0,0,0.15);
  padding: 16px;
  margin-top: 8px;
  width: 300px;
}
.admin-dashboard__day-picker-clear {
  margin-top: 12px;
  padding: 8px 12px;
  background-color: #f1f1f1;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  cursor: pointer;
  margin-left: 12px;
  transition: background-color 0.2s ease;
}
.admin-dashboard__day-picker-clear:hover:not(:disabled) {
  background-color: #e5e5e5;
}
.admin-dashboard__day-picker-clear:active:not(:disabled) {
  background-color: #d9d9d9;
}
.admin-dashboard__day-picker-clear:disabled {
  color: #999;
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.admin-dashboard__date-filter-buttons {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}
.admin-dashboard__date-filter-apply,
.admin-dashboard__date-filter-clear {
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
}
.admin-dashboard__date-filter-apply {
  background-color: #5057ff;
  color: #fff;
}
.admin-dashboard__date-filter-apply:hover:not(:disabled) {
  background-color: #2f37ff;
}
.admin-dashboard__date-filter-apply:active:not(:disabled) {
  background-color: #1e27ff;
}
.admin-dashboard__date-filter-apply:disabled {
  background-color: #7379ff;
  cursor: not-allowed;
}
.admin-dashboard__date-filter-clear {
  background-color: #f1f1f1;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  cursor: pointer;
  margin-left: 12px;
  transition: background-color 0.2s ease;
}
.admin-dashboard__date-filter-clear:hover:not(:disabled) {
  background-color: #e5e5e5;
}
.admin-dashboard__date-filter-clear:active:not(:disabled) {
  background-color: #d9d9d9;
}
.admin-dashboard__date-filter-clear:disabled {
  color: #999;
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.admin-dashboard__date-filter-summary {
  margin-top: 16px;
  padding: 10px 14px;
  background-color: #f8f9fa;
  border-radius: 6px;
  font-size: 14px;
  color: #495057;
  border-left: 3px solid #5057ff;
}
.admin-dashboard__user-filter {
  margin-top: 24px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}
.admin-dashboard__user-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.admin-dashboard__user-filter-label {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}
.admin-dashboard__user-filter-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}
.admin-dashboard__user-filter-type {
  display: flex;
  gap: 16px;
}
.admin-dashboard__user-filter-type label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-size: 14px;
}
.admin-dashboard__user-filter-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
}
.admin-dashboard__user-filter-input:focus {
  outline: none;
  border-color: #5057ff;
}
.admin-dashboard__user-filter-buttons {
  display: flex;
  gap: 8px;
}
.admin-dashboard__user-filter-apply {
  background-color: #5057ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}
.admin-dashboard__user-filter-apply:hover {
  background-color: #2f37ff;
}
.admin-dashboard__user-filter-apply:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.admin-dashboard__user-filter-clear {
  background-color: #fff;
  color: #2d3e4e;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}
.admin-dashboard__user-filter-clear:hover {
  background-color: #f5f5f5;
  border-color: #d0d0d0;
}
.admin-dashboard__user-filter-clear:disabled {
  color: #ccc;
  border-color: #eee;
  cursor: not-allowed;
}
.admin-dashboard__user-filter-summary {
  margin-top: 8px;
  font-size: 14px;
  color: #5057ff;
  font-weight: 500;
}
@media (max-width: 768px) {
  .admin-dashboard {
    padding: 16px;
  }
  .admin-dashboard__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .admin-dashboard__actions {
    width: 100%;
  }
  .admin-dashboard__refresh-button {
    width: 100%;
    justify-content: center;
  }
  .admin-dashboard__date-filter-inputs {
    flex-direction: column;
    align-items: stretch;
  }
  .admin-dashboard__date-picker-container {
    width: 100%;
  }
  .admin-dashboard__date-filter-buttons {
    flex-direction: column;
  }
  .admin-dashboard__table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
}
.status-badge.status-active {
  background-color: #e6f7e6;
  color: #2e7d32;
}
.status-badge.status-used {
  background-color: #e3f2fd;
  color: #1565c0;
}
.status-badge.status-expired {
  background-color: #f5f5f5;
  color: #757575;
}
.DayPicker {
  font-family: inherit;
}
.DayPicker-Day {
  border-radius: 0;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #5057ff;
  color: #fff;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #2f37ff;
}
.DayPicker-Day--today {
  color: #5057ff;
  font-weight: bold;
}
.DayPicker-Day:hover {
  background-color: #f0f0f0;
}
.DayPicker-Caption {
  padding: 0.5em;
  text-align: center;
}
.DayPicker-Weekday {
  color: #555;
  font-size: 0.9em;
}
.DayPicker-NavButton {
  color: #5057ff;
}
.DayPicker-NavButton:hover {
  opacity: 0.8;
}
.user-details {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.user-details__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.user-details__header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.user-details__status {
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
}
.user-details__status.registered {
  background-color: #e6f4ea;
  color: #1e4620;
}
.user-details__status.guest {
  background-color: #fce8e6;
  color: #c5221f;
}
.user-details__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.user-details__info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}
.user-details__info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.user-details__info-item label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}
.user-details__info-item span {
  font-size: 15px;
  color: #333;
}
.user-details__shares h3 {
  font-size: 16px;
  margin: 0 0 16px;
  color: #333;
}
.user-details__shares-grid {
  display: grid;
  gap: 16px;
}
@media (min-width: 640px) {
  .user-details__shares-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .user-details__shares-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.user-details__share-item {
  background: #f8f9fa;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.user-details__share-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.user-details__share-info label {
  font-size: 12px;
  color: #666;
  font-weight: 500;
}
.user-details__share-info span {
  font-size: 14px;
  color: #333;
  word-break: break-word;
}
@media (max-width: 639px) {
  .user-details {
    padding: 16px;
  }
  .user-details__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .user-details__status {
    align-self: flex-start;
  }
  .user-details__info-grid {
    grid-template-columns: 1fr;
  }
}
.admin-dashboard__graphs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 24px;
  margin-bottom: 36px;
}
.admin-dashboard__graph-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  padding: 24px;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid rgba(0,0,0,0.03);
}
.admin-dashboard__graph-container:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}
.admin-dashboard__graph-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #2d3e4e;
}
.admin-dashboard__no-graph-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  color: #6c757d;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 4px;
}
.admin-dashboard__pie-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-dashboard__graph-legend {
  margin-top: 16px;
}
.rv-xy-plot {
  font-size: 12px;
}
.rv-xy-plot__grid-lines {
  stroke: #e0e0e0;
  stroke-width: 1;
}
.rv-xy-plot__axis__tick__line {
  stroke: #e0e0e0;
}
.rv-xy-plot__axis__tick__text {
  fill: #6c757d;
  font-size: 11px;
}
.rv-xy-plot__axis__title {
  fill: #495057;
  font-weight: 500;
}
.rv-discrete-color-legend {
  display: flex;
  justify-content: center;
}
.rv-discrete-color-legend-item {
  padding: 6px 10px;
  font-size: 12px;
}
.rv-hint {
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}
.rv-hint__content {
  padding: 4px 0;
}
.rv-hint__content h3 {
  font-size: 11px;
  font-weight: normal;
  margin: 0 0 2px 0;
  color: #ccc;
}
.rv-hint__content p {
  margin: 0;
  font-weight: 500;
}
.admin-dashboard__hint {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 8px 12px;
  font-size: 12px;
  color: #333;
  pointer-events: none;
  z-index: 10;
}
.admin-dashboard__hint .rv-hint__content {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.admin-dashboard__hint .rv-hint__content table {
  border-collapse: collapse;
  width: 100%;
}
.admin-dashboard__hint .rv-hint__content table td {
  padding: 3px 6px;
}
.admin-dashboard__hint .rv-hint__content table td:first-child {
  font-weight: 500;
  color: #666;
}
.admin-dashboard__hint .rv-hint__content table td:last-child {
  font-weight: 600;
  text-align: right;
}
.admin-add-co-brand-form__header {
  padding: 48px 0;
}
.admin-add-co-brand-form__wrapper {
  border-bottom: 1px solid #e6e7ec;
  display: flex;
}
.admin-add-co-brand-form__wrapper .input-scaffold {
  margin-bottom: 40px;
}
.admin-add-co-brand-form__wrapper__column {
  margin-right: 24px;
  width: 50%;
}
.admin-add-co-brand-form__wrapper__column:last-of-type {
  margin-right: 0;
  margin-left: 24px;
}
.admin-add-co-brand-form__footer {
  display: flex;
  justify-content: flex-end;
  padding: 40px 0;
  width: 100%;
}
.admin-add-co-brand-form .input-label {
  display: flex;
  align-items: center;
}
.admin-add-co-brand-form .input-label button {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.admin-add-co-brand-form__preview {
  overflow-x: hidden;
}
.admin-add-co-brand-form__preview .input-scaffold.header-preview .user-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
}
.admin-add-co-brand-form__preview .email-header-preview {
  background-color: #fff;
  display: flex;
  flex-wrap: nowrap;
  padding: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.admin-add-co-brand-form__preview .email-header-preview img,
.admin-add-co-brand-form__preview .email-header-preview svg {
  margin: 0 24px;
}
.co-brand {
  background: #fff;
  border: 1px solid #e6e7ec;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 24px;
  font-weight: 600;
}
.co-brand .co-brand__logo {
  width: 36%;
}
.co-brand .co-brand__name {
  font-size: 20px;
  width: 27%;
}
.co-brand .co-brand__domains {
  font-size: 16px;
  width: 27%;
}
.co-brand .co-brand__kebab-menu {
  display: flex;
  justify-content: flex-end;
  width: 10%;
}
.co-brand .co-brand__kebab-menu .kebab-menu {
  margin-right: 0;
}
.admin-dashboard__date-filter {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.admin-dashboard__date-filter-label {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
}
.admin-dashboard__date-filter-inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 15px;
}
.admin-dashboard__date-picker-container {
  display: flex;
  flex-direction: column;
}
.admin-dashboard__date-picker-container label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}
.admin-dashboard__date-picker {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 150px;
}
.admin-dashboard__date-picker:focus {
  outline: none;
  border-color: #5057ff;
  box-shadow: 0 0 0 2px rgba(80,87,255,0.2);
}
.admin-dashboard__date-filter-buttons {
  display: flex;
  gap: 10px;
}
.admin-dashboard__date-filter-apply,
.admin-dashboard__date-filter-clear {
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;
}
.admin-dashboard__date-filter-apply {
  background-color: #5057ff;
  color: #fff;
}
.admin-dashboard__date-filter-apply:hover {
  background-color: #2f37ff;
}
.admin-dashboard__date-filter-apply:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}
.admin-dashboard__date-filter-clear {
  background-color: #f1f1f1;
  color: #333;
}
.admin-dashboard__date-filter-clear:hover {
  background-color: #d9d9d9;
}
.admin-dashboard__date-filter-clear:disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}
@media (max-width: 768px) {
  .admin-dashboard__date-filter-inputs {
    flex-direction: column;
    align-items: stretch;
  }
  .admin-dashboard__date-picker {
    width: 100%;
  }
  .admin-dashboard__date-filter-buttons {
    margin-top: 10px;
  }
}
.react-datepicker {
  font-family: inherit;
  border-color: #ddd;
}
.react-datepicker__header {
  background-color: #f8f9fa;
  border-bottom-color: #ddd;
}
.react-datepicker__navigation {
  top: 10px;
}
.react-datepicker__day--selected {
  background-color: #5057ff;
}
.react-datepicker__day--selected:hover {
  background-color: #2f37ff;
}
.react-datepicker__day--keyboard-selected {
  background-color: rgba(80,87,255,0.5);
}
.react-datepicker__day:hover {
  background-color: #f0f0f0;
}
.react-datepicker__current-month {
  font-size: 14px;
}
.react-datepicker__day-name {
  color: #555;
}
.admin-dashboard__date-picker-wrapper {
  position: relative;
  width: 100%;
}
.admin-dashboard__day-picker-overlay {
  position: absolute;
  z-index: 10;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  padding: 10px;
  margin-top: 5px;
}
.admin-dashboard__day-picker-clear {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
}
.admin-dashboard__day-picker-clear:hover {
  background-color: #d9d9d9;
}
.DayPicker {
  font-family: inherit;
}
.DayPicker-Day {
  border-radius: 0;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #5057ff;
  color: #fff;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #2f37ff;
}
.DayPicker-Day--today {
  color: #5057ff;
  font-weight: bold;
}
.DayPicker-Day:hover {
  background-color: #f0f0f0;
}
.DayPicker-Caption {
  padding: 0.5em;
  text-align: center;
}
.DayPicker-Weekday {
  color: #555;
  font-size: 0.9em;
}
.DayPicker-NavButton {
  color: #5057ff;
}
.DayPicker-NavButton:hover {
  opacity: 0.8;
}
.get-started--header-wrapper {
  margin-bottom: 40px;
  text-align: center;
}
.get-started--form > * + * {
  margin-top: 40px;
}
.questionnaire--inner h1,
.questionnaire--inner h4 {
  text-align: center;
}
.questionnaire--inner h1 {
  margin-bottom: 16px;
}
.questionnaire--inner h2 {
  text-align: left;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 24px;
}
.questionnaire--inner h3 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}
.questionnaire--inner h4 {
  font-size: 18px;
  font-weight: 400;
  color: #616a70;
}
.questionnaire--header {
  margin-bottom: 40px;
}
.questionnaire--block__inner > * + * {
  margin-top: 40px;
}
.questionnaire-blocks__container > * + * {
  margin-top: 48px;
}
.questionnaire--button__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  border-top: 1px solid #c8ccdb;
  padding: 32px 0;
}
.questionnaire--button__wrapper.next-only {
  justify-content: flex-end;
}
.conditions-button__wrapper {
  margin-bottom: 48px;
}
@media (max-width: 400px) {
  .conditions-button__wrapper .button {
    white-space: normal;
    height: auto;
  }
}
@media (max-width: 700px) {
  .questionnaire--inner h2 {
    font-size: 26px;
  }
}
.launch-conditions-flipper {
  margin-bottom: 48px;
}
.flipper--tab__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #e6e7ec;
}
.flipper--tab__wrapper .flipper-button {
  flex: 0 1 50%;
  background-color: #fff;
  padding: 24px 0;
  color: #576975;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 4px solid #fff;
}
.flipper--tab__wrapper .flipper-button.active {
  border-bottom: 4px solid #5057ff;
  color: #5057ff;
  font-weight: 600;
}
.flippable {
  background-color: #fff;
  padding: 40px;
  border: 1px solid #e6e7ec;
  border-top: 0;
}
.zip-code--field {
  margin-bottom: 48px;
}
.zip-code--field input[type="text"] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.zip-code--field .button {
  height: 64px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 480px) {
  .zip-code--field .button {
    height: 48px;
  }
}
.label-component {
  margin-bottom: 16px;
  margin-top: 16px;
}
.label-component h4 {
  margin-bottom: 8px;
  margin-top: 8px;
  text-align: left;
}
.label-component span {
  color: #5057ff;
}
.recommendations-wrapper .rec-value {
  font-weight: 600;
  color: #5057ff;
}
.static-results__wrapper h1 {
  padding: 16px 48px;
  text-align: left;
}
.static-results__wrapper h2,
.static-results__wrapper p {
  font-size: 20px;
  text-align: left;
  padding: 8px 48px;
}
.static-results__wrapper h2 {
  font-weight: 700;
}
.results--sub-nav {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #dde3eb;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 48px;
}
.results__sub-nav--left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.results__sub-nav--left__link {
  padding: 24px 48px;
  border-bottom: 4px solid transparent;
  color: #576975;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}
.results__sub-nav--left__link.active {
  color: #2d3e4e;
  border-bottom: 4px solid #5057ff;
}
@media (max-width: 768px) {
  .results__sub-nav--left__link {
    padding: 16px 32px;
  }
}
@media (max-width: 480px) {
  .results__sub-nav--left__link {
    padding: 12px 16px;
    font-size: 14px;
  }
}
.results__sub-nav--right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.results__sub-nav--right__button {
  padding: 24px 48px;
  color: #5057ff;
  line-height: 20px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .results__sub-nav--right__button {
    padding: 12px 16px;
    font-size: 14px;
  }
  .results__sub-nav--right__button svg {
    width: 20px;
    height: 20px;
  }
}
.results--top__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
@media (max-width: 900px) {
  .results--top__container {
    padding: 0 16px;
  }
}
.results--top__container__mobile {
  display: none;
  font-weight: 700;
  margin-bottom: 24px;
  padding: 0 16px;
}
@media (max-width: 900px) {
  .results--top__container__mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .results--top__container {
    display: none;
  }
}
.results--loading-indicator {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 32px 0;
}
.results--loading-indicator h4 {
  color: #5057ff;
  font-weight: 600;
}
.results--loading-indicator .loader {
  padding: 64px 64px 0;
}
.results--loading-indicator > * + * {
  margin-top: 24px;
}
.results--header {
  display: block;
  font-weight: 700;
  margin-bottom: 32px;
}
.results--header__mobile {
  display: none;
  font-weight: 700;
  margin-bottom: 24px;
  padding: 0 16px;
}
@media (max-width: 900px) {
  .results--header__mobile {
    display: block;
  }
  .results--header {
    display: none;
  }
}
.results-remaining {
  font-size: 18px;
  font-weight: 600;
  color: #5057ff;
  grid-column-start: 1;
  justify-self: start;
  align-self: center;
}
.results-list__wrapper {
  padding: 48px;
}
.results-list__wrapper .st-inline-share-buttons {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  grid-column-start: 2;
  justify-self: end;
  align-self: center;
  flex-grow: 1;
  width: 100%;
}
.results-list__wrapper .st-btn {
  font-family: "Inter", "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", -apple-system, BlinkMacSystemFont, sans-serif;
}
.results--arrow-buttons__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.results--arrow-buttons__wrapper > * + * {
  border-left: 0 !important;
}
.results--arrow-button {
  color: #5057ff;
  background-color: #fff;
  border: 1px solid #dde3eb;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.results--arrow-button:hover {
  background-color: #dde3eb;
}
.results--arrow-button:disabled {
  cursor: not-allowed;
}
.results--arrow-button:disabled:hover {
  background-color: #fff;
}
.results--arrow-button:disabled svg {
  fill: #a1a9ae;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 18px;
}
table th,
table td {
  padding: 0;
  vertical-align: middle;
}
table td {
  color: #616a70;
}
.results-table {
  display: block;
}
.results-table table {
  width: 100%;
}
.results-arrows--th {
  padding: 0;
  vertical-align: top;
}
.ball-attribute--th .inner {
  padding: 24px 24px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}
.ball-attribute--th .inner .attribute-icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.ball-attribute--th .inner .attribute-icon svg {
  width: 32px;
  height: 32px;
}
.ball-attribute--th .inner .ball-attribute--label {
  line-height: 1.25;
}
.ball-attribute--th .inner button {
  margin-left: 8px;
  width: 24px;
  height: 24px;
}
.ball-attribute--th .confidence-interval {
  text-align: left;
  margin-left: 48px;
  padding: 8px 24px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #576975;
}
.ball-attribute--th .confidence-interval.mobile {
  padding: 0;
  margin-left: 16px;
}
.ball-attribute--label {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  font-weight: 600;
}
.ball-header--th {
  vertical-align: top;
}
.ball-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 48px 16px 32px;
  position: relative;
}
.ball-logo {
  margin-bottom: 8px;
}
.ball-logo img {
  width: 100%;
  max-width: 180px;
  height: 47px;
}
.ball-image {
  width: 120px;
  margin-top: 16px;
  position: relative;
}
.ball-image img {
  width: 120px;
  height: 120px;
}
.ball-buy-now {
  margin-top: 40px;
}
.buy-now-bottom {
  border-top: 0 !important;
  padding-top: 14px;
}
.cross-section--button {
  position: absolute;
  bottom: 0;
  right: -24px;
}
.ball-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
  line-height: 1.5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.ball-name .current-ball--bullet {
  color: #576975;
}
.ball-name > * + * {
  padding-left: 8px;
}
.ball-match {
  color: #5057ff;
  font-weight: 600;
  text-align: center;
  line-height: 1.25;
}
.ball-tag--current,
.ball-tag--best,
.ball-tag--favorite {
  position: absolute;
  top: 0;
  padding: 4px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  margin: 8px;
}
.ball-tag--current {
  position: relative;
  top: unset;
  color: #576975;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.ball-tag--best {
  right: 0;
  background-color: #248227;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ball-tag--favorite {
  left: 0;
  background-color: #5057ff;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ball-data--td {
  border-top: 1px solid #c8ccdb;
  font-size: 16px;
  font-weight: 600;
}
.ball-data--td .inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
}
.value-trophy__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.trophy {
  margin-right: 4px;
}
.value {
  line-height: 1.25;
}
.change--positive,
.change--negative {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
}
.change--positive {
  color: #248227;
}
.change--negative {
  color: #e30033;
}
.favorite-checkboxes__fieldset {
  margin-top: 24px;
  width: 100%;
  max-width: 350px;
}
.favorite-checkboxes__fieldset .legend-mobile {
  display: none;
}
.favorite-checkboxes__fieldset .legend-desktop {
  display: flex;
}
.favorite-checkboxes__fieldset legend {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.favorite-checkboxes__fieldset legend > * + * {
  margin-left: 8px;
}
.favorite-ball__fieldset--inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.favorite-ball__fieldset--inner > * + * {
  margin-top: 16px;
}
.favorite-ball--td {
  background-color: #f1f2fa;
}
.favorite-ball--td .value {
  color: #2d3e4e;
}
.favorite-ball--input__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.ball-results__mobile {
  display: none;
}
.main-ball-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.favorite-checkboxes__mobile {
  padding: 0 24px 24px;
}
.value-columns__mobile,
.favorite-checkboxes__mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.value-columns__mobile > div,
.favorite-checkboxes__mobile > div {
  width: 100%;
}
.ball-info--top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cross-section-modal__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.cross-section-modal__title img {
  width: 100%;
  max-width: 240px;
  height: auto;
  margin-bottom: 16px;
}
.cross-section-img {
  width: 100%;
}
@media (max-width: 1024px) {
  table {
    font-size: 16px;
  }
  .ball-data--td .inner {
    padding: 16px;
  }
  .ball-attribute--th .inner {
    padding: 16px;
    font-size: 16px;
  }
  .ball-attribute--th .inner .attribute-icon {
    width: 24px;
    height: 24px;
  }
  .ball-attribute--th .inner .attribute-icon svg {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 960px) {
  .results--sub-nav {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (max-width: 900px) {
  .results--sub-nav {
    padding: 0;
  }
  .results__sub-nav--left {
    width: 100%;
  }
  .results-table {
    display: none;
  }
  .ball-results__mobile {
    display: block;
  }
  .results-list__wrapper {
    padding: 24px 0;
  }
  .main-ball-info {
    align-items: stretch;
  }
  .ball-header--th {
    width: 100%;
  }
  .ball-header {
    max-width: unset;
    justify-content: flex-start;
    height: 100%;
  }
  .ball-name {
    font-size: 16px;
  }
  .value {
    text-align: center;
  }
  .ball-attribute--th .inner {
    background-color: #fff;
    border-top: 1px solid #dde3eb;
    border-bottom: 1px solid #dde3eb;
    font-size: 18px;
  }
  .ball-attribute--th .inner .attribute-icon {
    width: 24px;
    height: 24px;
  }
  .ball-attribute--th .inner .attribute-icon svg {
    width: 24px;
    height: 24px;
  }
  .ball-buy-now {
    margin-top: 32px;
  }
  .ball-data--td {
    border-top: 0;
  }
  .ball-data--td .inner {
    padding: 24px;
  }
  .buy-now-bottom {
    padding-top: 10px;
  }
  .value-columns__mobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }
  .value-columns__mobile > div {
    width: 100%;
  }
  .ball-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cross-section--button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media (max-width: 480px) {
  .ball-name {
    font-size: 14px;
    min-height: 50px;
  }
  .results__sub-nav--left__link {
    white-space: normal;
  }
  .cross-section-modal__title {
    font-size: 20px;
  }
  .cross-section-modal__title img {
    max-width: 200px;
  }
}
@media (max-width: 400px) {
  .ball-name {
    font-size: 14px;
  }
  .ball-attribute--th .inner {
    font-size: 16px;
  }
  .ball-attribute--th .inner .attribute-icon {
    width: 20px;
    height: 20px;
  }
  .ball-attribute--th .inner .attribute-icon svg {
    width: 20px;
    height: 20px;
  }
  .ball-data--td .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 24px 16px;
  }
  .ball-data--td .inner > * + * {
    margin-top: 8px;
  }
}
.send-results--modal .modal__children {
  max-width: 560px;
}
.single-recipient__wrapper {
  margin-bottom: 32px;
}
.recipient-list__wrapper {
  font-size: 20px;
}
.recipient-list__wrapper .add-recipient--button {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 32px;
}
.recipient-list--title {
  font-size: 18px;
  font-weight: 600;
  color: #576975;
  margin-bottom: 16px;
}
.recipient-list .recipient-list__hr {
  margin: 24px 0 !important;
}
.recipient-list > * + * {
  margin-top: 16px;
}
.recipient {
  color: #505d6f;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.recipient > * + * {
  margin-left: 4px;
}
.recipient .remove-recipient__wrapper {
  position: relative;
}
.recipient .remove-recipient__wrapper .confirmation-tooltip {
  top: 32px;
  right: -80px;
}
.recipient .remove-recipient {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}
.recipient .remove-recipient > * + * {
  margin-left: 4px;
}
.recipient .remove-recipient .remove-recipient__button-label {
  font-size: 14px;
  font-weight: 500;
  color: #e30033;
}
@media (max-width: 480px) {
  .recipient {
    flex-direction: column;
    align-items: flex-start;
  }
  .recipient > * + * {
    margin-left: 0;
    margin-top: 8px;
  }
  .recipient .arroba {
    display: none;
  }
  .recipient .remove-recipient {
    margin-left: 0;
  }
}
.recipient--email {
  font-family: "Source Code Pro", monospace;
  font-size: 18px;
  font-weight: 600;
  word-break: break-word;
}
@media (max-width: 400px) {
  .recipient--email {
    font-size: 16px;
  }
}
.recipient--name {
  font-size: 16px;
  font-weight: 600;
  color: #2d3e4e;
}
.results-sent__flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.results-sent__flex > * + * {
  margin-top: 8px;
}
.results-sent--title {
  font-weight: 600;
  color: #248227;
}
.add-new-recipient--form {
  background-color: #f5f6fa;
  padding: 16px;
  margin-top: 24px;
  position: relative;
}
.add-new-recipient--form h4 {
  color: #505d6f;
  margin-bottom: 16px;
}
.add-new-recipient--form .add-new-recipient--form__close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.add-new-recipient--form .input-scaffold:first-of-type {
  margin-bottom: 16px;
}
.add-new-recipient--form input {
  font-size: 16px;
  padding: 13px 16px;
  height: auto;
}
.modal--fitter-comments {
  margin-top: 24px;
}
.modal--fitter-comments .input-label {
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
}
.modal--fitter-comments textarea {
  padding: 12px 16px;
}
.static-results__fitter-comments p {
  line-height: 1.5;
}
@media (max-width: 900px) {
  .static-results__fitter-comments h1,
  .static-results__fitter-comments h2,
  .static-results__fitter-comments p {
    padding-left: 16px;
    padding-right: 16px;
  }
  .static-results__fitter-comments h1 {
    font-size: 28px;
  }
  .static-results__fitter-comments p {
    font-size: 14px;
  }
}
.explore--disclaimer,
.results--disclaimer {
  padding: 24px 48px 48px;
  background-color: #fff;
  line-height: 1.5;
  font-size: 14px;
  color: #576975;
}
.results--disclaimer {
  background-color: transparent;
}
.get-results--payment__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #dde3eb;
}
.get-results--payment__wrapper h1 {
  font-size: 28px;
  font-weight: 700;
  margin: 16px 0;
}
.get-results--payment__wrapper .button {
  margin-top: 32px;
}
.get-results--disclaimer__empty-state {
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  width: 100%;
  max-width: 700px;
  color: #505d6f;
  font-weight: 500;
}
.get-results--disclaimer__empty-state ul {
  margin: 16px 0;
}
.get-results--disclaimer__empty-state ul > * + * {
  margin-top: 16px;
}
.get-results--disclaimer__empty-state svg {
  vertical-align: bottom;
  margin-bottom: -2px;
}
.payment-form--button__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #e6e7ec;
}
.payment-form--button__wrapper > * + * {
  margin-left: 16px;
}
.payment-modal .modal--title {
  margin-bottom: 16px;
}
.payment-modal ul {
  font-weight: 600;
  list-style: disc;
  padding-inline-start: 20px;
}
.braintree-form__descriptor {
  color: #576975 !important;
}
.braintree-placeholder:empty {
  display: none !important;
}
.billing-location-form {
  margin-top: 16px;
}
.billing-location-form h3 {
  margin-bottom: 24px;
}
.billing-location__scaffold > * + * {
  margin-top: 32px;
}
.invoice-table {
  width: 100%;
}
.invoice-table th,
.invoice-table td {
  padding: 16px 0;
}
.invoice-table th:nth-child(2) {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
}
.invoice-table td:nth-child(2) {
  font-weight: 700;
  color: #2d3e4e;
}
.invoice-table th:nth-child(2),
.invoice-table td:nth-child(2) {
  text-align: right;
}
.invoice-table thead tr {
  border-bottom: 1px solid rgba(87,105,117,0.25);
}
.invoice-table tbody tr:last-child td:first-child {
  font-weight: 700;
}
.promo-code--form {
  margin: 16px 0;
}
.promo-code--form .button {
  margin-top: 16px;
}
.promo-discount__wrapper .promo-code {
  font-size: 14px;
  font-family: monospace;
  color: #576975;
  background-color: #e6e7ec;
  padding: 4px 8px;
  font-weight: 700;
  display: inline-block;
}
.promo-discount__wrapper > * + * {
  margin-top: 8px;
}
.results-loading-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 170px;
}
.results-loading-indicator {
  font-weight: bold;
  padding: 120px 24px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.results-loading-indicator .loading-header-text {
  font-size: 24px;
  line-height: 1.25;
}
.results-loading-indicator .results-loading--left {
  position: relative;
  z-index: 9;
}
.results-loading-indicator .results-loading--left .loading-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.25;
}
.results-loading-indicator .result-loading--right {
  position: relative;
  z-index: 10;
}
.results-loading-indicator .loading-bar-container {
  margin: 24px 0 32px 0;
  width: 100%;
  position: relative;
}
.results-loading-indicator .loading-bar-container .progress-bar {
  height: 25px;
  border: 4px solid #222;
  border-radius: 16px;
  border-radius: 16px;
  z-index: -1;
}
.results-loading-indicator .loading-bar-container .progress-bar--fill {
  background-color: #5057ff;
  transition: width 300ms linear;
  height: 100%;
  border-radius: 16px;
}
.results-loading-indicator .golf-flag {
  padding-left: 53px;
  padding-bottom: 56px;
  position: relative;
}
.results-loading-indicator .loading-animation__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 16px;
}
.results-loading-indicator .loading-animation__wrapper svg {
  display: block;
}
.results-loading-indicator .loading-golf-ball__container {
  position: absolute;
  left: 0;
  bottom: 32px;
  opacity: 1;
  animation-name: ballpath, ballpath_down, ball_disappear;
  animation-duration: 1.5s, 1s, 0.5s;
  animation-delay: 10s, 11.5s, 11.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.results-loading-indicator .loading-golf-ball {
  animation-name: spin;
  animation-duration: 400ms;
  animation-delay: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  width: 32px;
  height: 32px;
}
.results-loading-indicator .flag-hole--flex__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 15%;
  z-index: -1;
}
.results-loading-indicator .hole-flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.results-loading-indicator .hole-flex .hole {
  width: 100%;
  height: auto;
}
.results-loading-indicator .flag__wrapper {
  position: absolute;
  bottom: 0;
  left: 39%;
  animation-name: flaglift;
  animation-duration: 500ms;
  animation-delay: 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.result-teaser .teaser-metric__wrapper > * + * {
  margin-top: 16px;
}
.result-teaser .did-you-know {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
}
.result-teaser .teaser-metric {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 32px;
  font-weight: 700;
  color: #e30033;
}
.result-teaser .teaser-metric--long-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
}
.result-teaser .feature-list .feature {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 8px;
}
.result-teaser .feature-list .feature > * + * {
  margin-left: 12px;
}
.result-teaser .feature-list .feature p {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
}
.result-teaser .price {
  margin: 32px 0;
  font-weight: 600;
  display: flex;
  align-items: baseline;
}
.result-teaser .price .price-text {
  font-size: 48px;
  margin-right: 8px;
}
.result-teaser .price .price-subtext {
  font-size: 20px;
}
.result-teaser button {
  width: 100%;
}
.result-teaser .testimonial-quote {
  font-size: 24px;
  line-height: 1.25;
}
.result-teaser .testimonial-author {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
}
.result-teaser .testimonial-author * + * {
  margin-left: 16px;
}
.result-teaser .testimonial-author-logo {
  width: 100%;
  max-width: 150px;
  filter: invert(0.3) sepia(1) hue-rotate(180deg);
}
@media (max-width: 600px) {
  .results-loading-indicator {
    padding: 64px 24px 64px;
  }
  .results-loading-indicator .results-loading--left .loading-text {
    font-size: 16px;
  }
  .results-loading-indicator .loading-bar-container {
    margin: 16px 0;
  }
  .results-loading-indicator .loading-header-text {
    font-size: 20px;
  }
  .results-loading-indicator .loading-golf-ball__container {
    opacity: 1;
    animation-name: ballpath_mobile, ballpath_down, ball_disappear;
    animation-duration: 1.5s, 1s, 0.5s;
    animation-delay: 10s, 11.5s, 11.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .results-loading-indicator .loading-golf-ball {
    width: 24px;
    height: 24px;
  }
  .results-loading-indicator .loading-animation__wrapper {
    width: 86%;
  }
  .results-loading-indicator .flag-hole--flex__wrapper {
    width: 20%;
  }
}
@media (max-width: 480px) {
  .result-teaser .testimonial-quote {
    font-size: 20px;
    line-height: 1.25;
  }
  .result-teaser .feature-list .feature p {
    font-size: 16px;
  }
  .result-teaser .testimonial-author > * + * {
    margin-left: 12px;
  }
  .result-teaser .teaser-metric--long-text {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .results-loading-indicator .loading-header-text {
    font-size: 18px;
  }
}
@media (max-width: 339px) {
  .results-loading-indicator .loading-header-text {
    font-size: 16px;
  }
}
@-moz-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes ballpath {
  from {
    left: 0%;
  }
  to {
    left: 88.5%;
  }
}
@-webkit-keyframes ballpath {
  from {
    left: 0%;
  }
  to {
    left: 88.5%;
  }
}
@-o-keyframes ballpath {
  from {
    left: 0%;
  }
  to {
    left: 88.5%;
  }
}
@keyframes ballpath {
  from {
    left: 0%;
  }
  to {
    left: 88.5%;
  }
}
@-moz-keyframes ballpath_mobile {
  from {
    left: 0%;
  }
  to {
    left: 85.5%;
  }
}
@-webkit-keyframes ballpath_mobile {
  from {
    left: 0%;
  }
  to {
    left: 85.5%;
  }
}
@-o-keyframes ballpath_mobile {
  from {
    left: 0%;
  }
  to {
    left: 85.5%;
  }
}
@keyframes ballpath_mobile {
  from {
    left: 0%;
  }
  to {
    left: 85.5%;
  }
}
@-moz-keyframes ballpath_down {
  from {
    bottom: 32px;
  }
  to {
    bottom: 0;
  }
}
@-webkit-keyframes ballpath_down {
  from {
    bottom: 32px;
  }
  to {
    bottom: 0;
  }
}
@-o-keyframes ballpath_down {
  from {
    bottom: 32px;
  }
  to {
    bottom: 0;
  }
}
@keyframes ballpath_down {
  from {
    bottom: 32px;
  }
  to {
    bottom: 0;
  }
}
@-moz-keyframes ball_disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes ball_disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes ball_disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes ball_disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes flaglift {
  from {
    bottom: 0;
  }
  to {
    bottom: 40px;
  }
}
@-webkit-keyframes flaglift {
  from {
    bottom: 0;
  }
  to {
    bottom: 40px;
  }
}
@-o-keyframes flaglift {
  from {
    bottom: 0;
  }
  to {
    bottom: 40px;
  }
}
@keyframes flaglift {
  from {
    bottom: 0;
  }
  to {
    bottom: 40px;
  }
}
.explore--ball-checkboxes__wrapper {
  padding: 40px 48px;
}
.explore--ball-checkboxes__wrapper h5 {
  font-size: 16px;
  font-weight: 500;
  color: #576975;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.compare-options__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.compare-options__wrapper .checkbox {
  margin: 0 64px 24px 0;
}
.chart-visualizer--nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 16px;
}
.chart-visualizer--nav > * + * {
  margin-left: -4px;
}
.chart-visualizer--nav a {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANwAAAA4CAYAAACWj5YVAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA3KADAAQAAAABAAAAOAAAAAC3NtN6AAAEW0lEQVR4Ae2dwW7TMBiA7UxAD2hjmsQmDtsuIA0YF6RJXHkEXgLBgQMPwp2X4MADcEXiLeBA22ldKeJAIY2xuzZqE/+xm+TC8u3U/v79d/7UT3UcJ9Gqxt9g8OO5UbOXxqhnWqsDY8yNGmXoAoH/ioDW+q/9zg/sd/6zVlvvDw7ufNp0AHqTDt/H4yP9O3tnlHmxST9yIXAdCWilP5he8vbe7u7X2PFFCzcc/tqfZdMvSpnD2OLkQeD6E9DftpJbZ/v7t4cxY01ikuyUsZeZ6Udki6FFTrcImEPnhnMkZtxRwg0G4ze24FlMQXIg0DUCzg3nSMy4o4Szv2yvY4qRA4HOEtDmVczYg8Kdn0/u20WSo5hi5ECgqwTsr9yxcyU0/qBwWTZ7GipCOwQgoFSMK2HhlDoFJgQgECaQRbgSFE6r7En4o8iAAARiXAkKZ4xGOL5LEIggEONKpXCXl5c7nHuLIE0KBOYEzOGVMzKOSuHSlOM3GR0tECgTCDlTKdzMKKaTZaZEICASCDlTKZxd52SFUkRLAwQ8BALOVApnNAsmHqSEICASCDkjCmfPnNvLftRjsTINEIBAiYBzxrlTalgEROGGw8mx7bgtdSQOAQiUCThnnDvllquIKJw9HcDxm0SNOAQqCcjuiMJlasYKZSVUGiHgJ1DljiicZoeJnyZRCAQIVLkjCmcvyWFKGQBLMwR8BKrc8QpnD/x69g5FwWt7fB9GDAJdJ+DccQ75OHiF619MHtkOW74OxCAAgWoCzh3nkC/LK5xOU6aTPlrEIBBJQHLILxw7TCKxkgYBPwE7rfSu8nuFswd93mR/aaIQgECRgOSQVzjbmSllkSDvIbAZAa9DJeHcHZbt/dPvblabbAhAYJWAc8i5tBpzr0vCKTVlOlmkxHsI1CJQdqkk3IzpZC20dIJAkYDPpZJwOuMq7yI43kOgDgGfSyXhpNWVOh9IHwh0mYDPpTXhFrtLTroMibFDoEUCJ8UdW2vC9UejB/bDvHvAWvwnKAWBrhDoLZzKx7smXJL6z47n2byAAAQ2IlB0ak24TCHcRjRJhkCAQNGpNeHsvdG9Z8cDNWmGAAQEAkWn1oRTXOUtYCMMgZoECk7lt/MajUbb0z9mUrMs3SAAAYHArZt6Z29v76drzn/h0lQznRSAEYZAEwKrbuXC2YfJsYeyCVX6QkAgsOpWLpx9jgDCCcAIQ6ARgRW3cuHsjc2ZUjaiSmcI+AmsupULZ1dPEM7PiygEGhFYdWsuXL8/5jkCjZDSGQIyAbufcts55jLmwmnNTV9lXLRAoDmBpWNz4TLFgklzpFSAgExg6djVLxx7KGVStECgBQJ64dhcODvH5JRAC1ApAQGJwNKxxL7gOQISJeIQaInA8nkDycXF5KGVjucItASWMhDwEXCOOdeSNE2ZTvoIEYNAywSca/YYjh0mLXOlHAQEAvo0MZpNywIdwhBolYBzLdE8uKNVqBSDgETAufYPxocpwCMLvN0AAAAASUVORK5CYII=");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  color: #a1a9ae;
  padding: 16px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 220px;
}
.chart-visualizer--nav a.active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANwAAAA4CAYAAACWj5YVAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA3KADAAQAAAABAAAAOAAAAAC3NtN6AAAD80lEQVR4Ae2dP27UQBSHYxASBdpQBSpYCiiCsg1SJFqOwCXoKDhIei5BkQPQInGKQEWoIKJAQmj5PbM7iT1/7diKtPONNIo9M37e+aJPY7+1vM3eiLJer1/psDeqL1Ufqt5RpUBg1wn80QS/qX5Sfd80zcehE26GHCDRHmv8ierrIccxFgI7SuCD5vVO4n0pnV+xcJLtgYJ+Vn1UGpxxEKiAwFfN8VjSnZfM9VbJIMl2V+NOVZGtBBhjaiJgTpxuHMnOu0g4RXmrepyNxgAI1EnA3DBHsqXoklL2nimS3b9RIACBMIEzXVY+CXddtmZXOMn2VMOR7ZIZWxAIEVhuXAn1ubascBr5wo1mAwIQSBHIulIi3FHqDPRBAAKOQNaVEuFWLhwbEIBAikDWFYRL4aMPAsMIZIVLZil1E7iv8/0Ydk5GQ6BqAveVrfwZI5Bb4bLXpLHAtEOgUgJJZ3LCZZfISqEybQjECCSdyQmXtDV2RtohUDGBpDM54ZK2VgyVqUMgRiDpTDRpooSJ9VnCZBGLTDsEIOARuFCLJU7WXo8aUivcUv3IFqJGGwTiBMyZZaw7JVzyWjQWkHYIQGAv6k5KuOS1KFAhAIEogag7CBdlRgcERhMYJVx0WRz9MTgQAnUQiLoTzFIqQ2mvVPilersOPswSApMS+Kto95Sp/N2PGrukfK6ByNanxT4EygiYO+aQV2LCRZdELwINEIBAiEDQoZhw0Zu+UGTaIAABj0DQIYTzONEAgUkIDBIuuBxO8jEIAoE6CAQd8lY4ZSjtDcsHdTBhlhCYjcDBxqXOCTzh1BtcCjtHsQMBCJQQ8FwKCRdcCkuiMwYCEOgQ8FwKCedZ2QnBDgQgUErAcwnhStExDgLDCXjCdR7t0k2efUNuj3TZo10UCEDgegTs0S57xMse9WpLf4V7plZk28DhDwSuScBcMqdc6QvnLYFuJBsQgMAYAh2nEG4MQo6BQDmBpHBeGrM8LiMhAIEAgY5TrHABQjRBYEICnRXOZSmVoVzoJNF3ok/4AQgFgdoI7CtTaa/P67wmr7P01UaE+UJgRgLOrauXlJ2lb8aTExoCtRFwbiFcbf965nsTBILCuWXvJj4R54TADhNwbl1NmljCxBInFAhAYFoCF0qa2I+b/k+aKEO51DayGREKBKYnsNg45rKUbsmb/lxEhAAERKB1bJs0cTd1oIEABGYh0DqGcLOwJSgEPAII5yGhAQLzEWiFa3Qzx+8IzAeZyBDYEmh/b8AuKQ9V+R2BLRb+QmAeAubYoQlHwmQewESFQJ/AyoTjK4E+FvYhMA+BI1a4ecASFQIhAitLmpyrh1ebh/DQBoFpCXz/B7RabmGl7z6WAAAAAElFTkSuQmCC");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  color: #505d6f;
  position: relative;
  z-index: 2;
}
.ball-flight-chart__wrapper {
  background-color: #fff;
  padding: 48px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.rv-discrete-color-legend {
  margin-left: 48px;
  overflow-y: unset;
}
.rv-discrete-color-legend > * + * {
  margin-top: 32px;
}
.rv-discrete-color-legend-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.legend-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.legend-item > img {
  width: 100%;
  max-width: 120px;
  height: auto;
  margin-bottom: 8px;
}
.legend-item .ball-diff__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.legend-item .ball-diff__wrapper span:first-child {
  color: #576975;
}
.legend-item .ball-diff__wrapper > * + * {
  margin-top: 8px;
}
.rv-discrete-color-legend-item {
  padding: 0;
}
.rv-discrete-color-legend-item__color {
  width: 32px;
  min-width: 32px;
  height: 32px;
  overflow: hidden;
  stroke-width: 40;
  border-radius: 4px;
}
.rv-discrete-color-legend-item__color path {
  d: path("M0 0h40v40H0z");
  stroke-width: 40 !important;
}
.rv-xy-plot__axis__title text {
  font-size: 20px;
  font-weight: 500;
  color: #505d6f;
}
.rv-xy-plot__axis__tick__text {
  fill: #576975;
  color: #576975;
}
.rv-discrete-color-legend-item__title {
  font-size: 14px;
  color: #9aa1a9;
}
.rv-discrete-color-legend-item__title .distance-difference {
  color: #248227;
  font-weight: 500;
  font-size: 18px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.into-wind--header {
  font-size: 20px;
  font-weight: 600;
  padding: 48px 48px 0;
  background-color: #fff;
}
@media (max-width: 1300px) {
  .ball-flight-chart__wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .rv-discrete-color-legend {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .rv-discrete-color-legend > * + * {
    margin-top: 0;
    margin-left: 64px;
  }
}
@media (max-width: 880px) {
  .ball-flight-chart__wrapper {
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
  }
}
@media (max-width: 600px) {
  .rv-discrete-color-legend {
    margin-left: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .rv-discrete-color-legend > * + * {
    margin-top: 48px;
    margin-left: 0;
  }
}
.library {
  margin: 48px 20px 20px 20px;
  flex: 1;
}
.library .loader {
  min-height: 100vh;
  flex: 1;
}
.library h1 {
  font-family: 'Roboto Slab', 'Times New Roman', serif;
}
.library--search-field {
  margin-bottom: 32px;
  position: relative;
}
.library--search-field .search-icon {
  position: absolute;
  top: calc(50% - 14px);
  left: 24px;
}
.library--search-field .search-icon svg {
  width: 28px;
  height: 28px;
  fill: #a1a9ae;
}
.library--search-field input[type="text"] {
  padding-left: 68px;
  padding-right: 24px;
  font-size: 20px;
}
@media (max-width: 768px) {
  .library--search-field .search-icon {
    top: calc(50% - 10px);
    left: 16px;
  }
  .library--search-field .search-icon svg {
    width: 20px;
    height: 20px;
    fill: #a1a9ae;
  }
  .library--search-field input[type="text"] {
    padding-left: 48px;
    padding-right: 16px;
    font-size: 16px;
  }
}
.article {
  flex: 1;
}
.article-item--list > * + * {
  margin-top: 48px;
}
.article-item {
  padding-bottom: 48px;
  border-bottom: 1px solid #c8ccdb;
}
.article-item .article-item__title {
  margin-bottom: 8px;
}
.article-item .article-item__title a {
  color: #2d3e4e;
}
.article-item .article-item__preview {
  font-size: 18px;
  line-height: 1.5;
  color: #616a70;
  margin-bottom: 24px;
}
@media (max-width: 480px) {
  .article-item .article-item__preview {
    font-size: 16px;
  }
}
.article-item .article-item__read-more-link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.article-item .article-item__read-more-link > * + * {
  margin-left: 4px;
}
.article__main-img {
  width: 100%;
  position: relative;
  background-color: #575bff;
}
.article__main-img img {
  display: block;
  width: 100%;
  max-width: 1018px;
  height: auto;
  margin: 0 auto;
}
.article__go-back {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  margin-bottom: 24px;
}
.article__go-back > * + * {
  margin-left: 4px;
}
.article__main-img--go-back {
  width: 100%;
  margin-top: 0;
  padding-top: 48px;
  padding-bottom: 48px;
  position: relative;
  z-index: 2;
}
.article__main-img--go-back .article__go-back {
  color: #fff;
}
.go-back__filter {
  width: 100%;
  padding: 48px;
  background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0));
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.article .article__title {
  margin-bottom: 16px;
}
.article .article__body {
  padding-bottom: 48px;
  margin-bottom: 64px;
  border-bottom: 1px solid #c8ccdb;
  font-size: 16px;
  line-height: 1.5;
  color: #505d6f;
}
.article__body--set-inner > p {
  margin-top: 32px;
}
.article__body--set-inner > p:first-child {
  margin-top: 0;
}
.my-account-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 375px) {
  .my-account-page {
    padding: 2rem 1rem;
  }
}
.my-account-page h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
}
.my-account-sections-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
}
@media (max-width: 375px) {
  .my-account-sections-grid {
    grid-template-columns: 1fr;
  }
}
.my-account-section {
  background: #fff;
  font-weight: 500;
  font-size: 1.15rem;
  border: 1px solid #00f;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.my-account-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.my-account-section-header h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #000;
  white-space: nowrap;
}
.my-account-edit-button,
.my-account-view-all-button {
  padding: 0.5rem 1rem;
  text-decoration: underline;
  color: #000;
  font-weight: 600;
  background: transparent;
  cursor: pointer;
  white-space: nowrap;
}
@media (min-width: 390px) {
  .my-account-edit-button,
  .my-account-view-all-button {
    padding-right: 0.5rem 1rem;
  }
}
.my-account-profile-info > div {
  margin-bottom: 0.75rem;
  color: #4a4a4a;
}
.my-account-codes-list,
.my-account-results-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.my-account-code-item,
.my-account-result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  font-size: 16px;
}
.status {
  font-weight: 500;
}
.status.redeemed {
  color: #666;
}
.status.redeem-now {
  color: #5057ff;
}
.view-results-button {
  color: #5057ff;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}
.billing-history-list {
  background: #fff;
}
.billing-history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}
.billing-history-item:last-child {
  border-bottom: none;
}
.billing-history-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.billing-history-item .order-id {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.billing-history-item .date {
  font-size: 12px;
  color: #000;
}
.billing-history-item .amount {
  font-size: 20px;
  font-weight: 500;
  padding-right: 16px;
  color: #000;
}
.my-account-link-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}
.page--my-profile {
  margin: 48px 32px 32px 32px;
}
.page--my-profile h1 {
  font-weight: 600;
  margin-bottom: 48px;
  color: #000;
}
@media (max-width: 375px) {
  .page--my-profile {
    margin: 2rem 1rem;
  }
}
.profile--name-form input[type="text"] {
  font-size: 18px;
}
.profile--name-form .button__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.profile--name-form > * + * {
  margin-top: 24px;
}
.password-management h3,
.email-settings h3,
.fitter-results-email-form h3 {
  margin-bottom: 32px;
}
.fitter-results-email-form__inner {
  width: 100%;
  max-width: 400px;
}
.fitter-results-email-form__inner > * + * {
  margin-top: 24px;
}
.current-email__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}
.current-email__wrapper > * + * {
  margin-left: 24px;
}
.current-email__wrapper .current-email {
  font-size: 18px;
  font-weight: 500;
}
.current-email__wrapper .email-status-tag {
  color: #e30033;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
}
.current-email__wrapper .email-status-tag.verified {
  color: #248227;
}
.billing-history__wrapper h3 {
  margin-bottom: 24px;
}
.table.billing-history {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e6e7ec;
}
.table.billing-history td {
  font-size: 14px;
  color: #2d3e4e;
}
.table.billing-history th,
.table.billing-history td {
  padding: 20px 24px;
  vertical-align: middle;
  text-align: left;
}
.table.billing-history th:first-child,
.table.billing-history td:first-child {
  padding-left: 48px;
}
.table.billing-history th:last-child,
.table.billing-history td:last-child {
  padding-right: 48px;
}
.table.billing-history th {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #576975;
}
.table.billing-history th:last-child {
  text-align: right;
}
.table.billing-history tbody {
  border-top: 1px solid rgba(211,225,231,0.5);
}
.table.billing-history tbody tr td:last-child {
  text-align: right;
}
.table.billing-history tbody > * + * {
  border-top: 1px solid rgba(211,225,231,0.5);
}
.table.billing-history .amount {
  font-weight: bold;
}
.table.billing-history .method {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}
.table.billing-history .method > * + * {
  margin-left: 8px;
}
@media (max-width: 430px) {
  .table.billing-history th,
  .table.billing-history td {
    padding: 20px 10px;
  }
  .table.billing-history th:first-child,
  .table.billing-history td:first-child {
    padding-left: 24px;
  }
  .table.billing-history th:last-child,
  .table.billing-history td:last-child {
    padding-right: 24px;
  }
}
.results-history--zero-content {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  jutify-content: flex-start;
  align-items: center;
  color: #576975;
}
.results-history--zero-content h3 {
  font-size: 24px;
  font-weight: 600;
}
.results-history--zero-content > * + * {
  margin-top: 24px;
}
.results-history-table tbody tr td:first-child a {
  font-weight: 600;
  word-break: break-word;
}
.codes-list {
  width: 100%;
}
.codes-list th {
  text-align: left;
  padding: 0 16px 16px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
}
.codes-list tbody > * + * {
  border-top: 1px solid #c8ccdb;
}
.codes-list td {
  padding: 8px 16px;
  font-size: 15px;
  border-bottom: 1px solid #c8ccdb;
  font-weight: 400;
  color: #000;
}
.purchased-codes-list {
  width: 100%;
  padding-right: 40px;
}
.purchased-codes-list th {
  text-align: left;
  padding: 0 16px 16px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
}
.purchased-codes-list td {
  padding: 32px 0 0 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
@media (min-width: 769px) {
  .purchased-codes-list td {
    padding: 32px 20px 0 0;
    font-size: 16px;
  }
}
.purchased-codes-list bd {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
@media (min-width: 769px) {
  .purchased-codes-list bd {
    font-size: 24px;
  }
}
.purchased-codes-list a {
  text-decoration: underline;
}
.purchased-text {
  padding: 16px;
  padding-left: 0;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  text-align: left;
}
@media (min-width: 769px) {
  .purchased-text {
    font-size: 22px;
  }
}
.view-all-text {
  padding-top: 42px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
@media (min-width: 769px) {
  .view-all-text {
    font-size: 22px;
  }
}
.view-all-here {
  font-size: 16px;
  font-weight: 400;
  color: #5057ff;
  display: inline;
}
@media (min-width: 769px) {
  .view-all-here {
    font-size: 22px;
  }
}
.purchase-voucher-history-table tbody tr td:first-child a {
  font-weight: 600;
  word-break: break-word;
}
.purchase-more-fitting {
  display: flex;
  justify-content: center;
  margin: 70px;
}
@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .hide-on-desktop {
    display: none;
  }
}
.landing-page-header .header {
  display: none;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: transparent;
  justify-content: space-between;
  padding: 32px 0;
}
@media (min-width: 1024px) {
  .landing-page-header .header {
    display: flex;
  }
}
.landing-page-header .header__logo-wrapper {
  display: flex;
  align-items: center;
}
.landing-page-header .header__logo-wrapper .header__co-brand {
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.landing-page-header .header__logo-wrapper .header__co-brand-logo {
  margin-left: 16px;
}
.landing-page-header .gift-button-wrapper {
  margin-right: auto;
  margin-left: 4%;
}
.landing-page-header .gift-button-wrapper .gift-button {
  padding: 12px 24px;
  text-transform: none;
}
.landing-page-header .nav-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.landing-page-header .nav-links .button {
  padding: 12px 24px;
  text-transform: none;
  border: none;
}
.landing-page-header .nav-links .button--active {
  background-color: #5057ff;
  color: #fff;
}
.landing-page-header .nav-links .button--rounded {
  border-radius: 5px;
}
.landing-page-header .nav-links > * + * {
  margin-left: 16px;
}
.landing-page-header .mobile-nav .login-button {
  padding: 8px;
  text-transform: none;
  border: 0;
}
.landing-page-header .dropdown-container {
  position: relative;
}
.landing-page-header .dropdown-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  padding: 6.5px;
  background-color: #fff;
  border: 1px solid rgba(80,87,255,0.5);
  border-radius: 2px;
}
.landing-page-header .dropdown-item {
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
}
.landing-page-header .dropdown-item:hover {
  background-color: #5057ff;
  color: #fff;
}
.ballnamic--landing-page {
  width: 100%;
  background-color: #fff;
}
.carousel-main--flex-container {
  padding-left: 8.33%;
}
#hero {
  width: 100%;
  background: linear-gradient(to bottom, #000 50%, #fff 50%);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#hero > div {
  width: 100%;
  padding: 22px;
  display: flex;
  justify-content: center;
}
#hero iframe {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
}
#hero h1 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 40px;
  margin: 40px 10px 20px;
  text-align: center;
}
#hero .button-startlifting-learnmore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px;
  gap: 10px;
  border-radius: 10px;
  margin: 48px 0 48px;
}
@media (min-width: 768px) {
  #hero h1 {
    font-size: 26px;
  }
}
@media (min-width: 1024px) {
  #hero iframe {
    height: 550px;
    width: auto;
  }
  #hero h1 {
    margin: 100px 0 20px;
    font-size: 30px;
  }
}
.main-content > div {
  padding: 24px 0px 96px;
  padding-left: 22px;
  padding-right: 22px;
}
@media (min-width: 768px) {
  .main-content > div {
    padding-top: 96px;
  }
}
@media (min-width: 1024px) {
  .main-content > div {
    padding-top: 96px;
  }
}
@media (min-width: 1280px) {
  .main-content > div {
    padding-top: 96px;
  }
}
@media (min-width: 1536px) {
  .main-content > div {
    padding-top: 96px;
  }
}
.main-content h2 {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
}
.main-content h2 strong {
  color: #5057ff;
}
.main-content #find-ball {
  max-width: 1200px;
  margin: 0 auto;
}
.main-content #find-ball .balls-list {
  display: flex;
  flex-direction: column;
  margin: 48px 0 0;
  column-gap: 24px;
  row-gap: 48px;
}
@media (min-width: 768px) {
  .main-content #find-ball .balls-list {
    flex-direction: row;
  }
}
.main-content #find-ball .balls-list .ball-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-content #find-ball .balls-list .ball-details img {
  height: 100px;
}
.main-content #find-ball .balls-list .ball-details h3 {
  text-align: center;
  font-size: 18px;
  margin: 32px 0 16px;
}
.main-content #find-ball .balls-list .ball-details p {
  font-size: 14px;
  line-height: 1.4rem;
  text-align: center;
}
@media (min-width: 768px) {
  .main-content #find-ball .balls-list .ball-details {
    width: 33%;
    flex: 0 0 auto;
  }
}
@media (min-width: 768px) {
  .main-content #find-ball .balls-list {
    margin: 96px 0 0;
  }
}
@media (min-width: 1024px) {
  .main-content #find-ball .balls-list {
    flex-direction: row;
    margin: 96px 0 0;
  }
}
@media (min-width: 1280px) {
  .main-content #find-ball .balls-list {
    flex-direction: row;
    margin: 96px 0 0;
  }
}
@media (min-width: 1536px) {
  .main-content #find-ball .balls-list {
    flex-direction: row;
    margin: 96px 0 0;
  }
}
.main-content #review-tested {
  background-color: #5057ff;
  padding: 60px 0;
}
.main-content #review-tested > div {
  max-width: 1200px;
  margin: 0 auto;
}
.main-content #review-tested .review-list {
  text-align: center;
}
.main-content #review-tested .review-list h2 {
  font-size: 16px;
  color: #fff;
  margin-bottom: 32px;
}
.main-content #review-tested .review-list h1 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 32px;
}
.main-content #review-tested .review-list h2:last-child {
  margin-bottom: 0;
}
.main-content #top-matches > p {
  font-size: 16px;
  line-height: 1.4rem;
  text-align: center;
}
.main-content #top-matches .top-matches-list {
  margin: 48px 0 0;
  display: flex;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 48px;
}
.main-content #top-matches .top-matches-list .top-match {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: none;
  background: linear-gradient(to bottom, #e7ebfa 50%, #f5f6fa 50%);
  padding: 24px 60px;
  border-radius: 10px;
}
.main-content #top-matches .top-matches-list .top-match p {
  background-color: #444ada;
  color: #fff;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 100px;
  white-space: nowrap;
}
.main-content #top-matches .top-matches-list .top-match h4 {
  font-size: 32px;
}
.main-content #top-matches .top-matches-list .top-match h3 {
  font-size: 16px;
}
.main-content #top-matches .top-matches-list .top-match img {
  width: 100%;
  max-width: 246px;
  margin: 16px 0;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.4);
  border-radius: 50%;
}
.main-content #top-matches .top-matches-list .top-match h2 {
  font-size: 20px;
}
@media (min-width: 768px) {
  .main-content #top-matches .top-matches-list {
    flex-direction: row;
    justify-content: center;
  }
  .main-content #top-matches .top-matches-list .top-match {
    width: 30%;
    padding: 24px 16px;
  }
  .main-content #top-matches .top-matches-list .top-match img {
    width: 100%;
    margin: 16px 0;
    box-shadow: 0px 10px 15px rgba(0,0,0,0.4);
    border-radius: 50%;
  }
}
@media (min-width: 1280px) {
  .main-content #top-matches .top-matches-list .top-match {
    width: 296px;
    padding: 24px 64px;
  }
}
.main-content #whats-included {
  background-color: #f5f6fa;
}
.main-content #whats-included .features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 24px;
  row-gap: 48px;
  margin: 48px 0 0;
}
.main-content #whats-included .features .feature {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 48px 18px;
  background-color: #fff;
  border-radius: 10px;
}
.main-content #whats-included .features .feature img {
  height: 100px;
}
.main-content #whats-included .features .feature h3 {
  margin: 32px 0 0;
  font-size: 20px;
  text-align: center;
}
.main-content #whats-included .features .feature p {
  margin: 16px 0 0;
  font-size: 14px;
  line-height: 1.4rem;
}
@media (min-width: 768px) {
  .main-content #whats-included .features {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
  .main-content #whats-included .features .feature {
    width: 30%;
    flex: 0 0 auto;
  }
}
@media (min-width: 1280px) {
  .main-content #whats-included .features .feature {
    width: 373px;
  }
}
.main-content #closer-look {
  max-width: 1200px;
  margin: 0 auto;
}
.main-content #closer-look .videos-list {
  display: flex;
  flex-direction: column;
}
.main-content #closer-look .videos-list .video-item {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.main-content #closer-look .videos-list .video-item .item-text {
  text-align: center;
}
.main-content #closer-look .videos-list .video-item .item-text p {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.4rem;
}
.main-content #closer-look .videos-list .video-item .item-video {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.main-content #closer-look .videos-list .video-item .item-video iframe {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
}
.main-content #closer-look .videos-list .button-startlifting {
  margin-top: 48px;
}
@media (min-width: 768px) {
  .main-content #closer-look .videos-list .video-item .item-video {
    width: 100%;
  }
}
.main-content #pricing {
  padding-left: 0;
  padding-right: 0;
  background: linear-gradient(to bottom, #000 50%, #fff 50%);
  text-align: center;
  overflow: none;
  max-width: 100vw;
  position: relative;
/* Pricing Title */
/* Pricing Options */
}
.main-content #pricing .lines {
  display: none;
}
.main-content #pricing h2 {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 40px;
  color: #fff;
  font-weight: normal;
  letter-spacing: 6%;
  line-height: 180%;
}
.main-content #pricing h3 {
  font-size: 32px;
  margin-bottom: 65px;
  font-weight: normal;
  color: #fff;
}
.main-content #pricing .pricing-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
/* Individual Pricing Option */
}
.main-content #pricing .pricing-options .pricing-option {
  flex: none;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: transform 0.3s;
/* Pricing Option Title */
/* Fake Pricing Button */
/* Pricing */
/* Features List */
/* Best Seller Highlight */
}
.main-content #pricing .pricing-options .pricing-option h4 {
  font-size: 64px;
  margin: 10px 0;
  color: #000;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0%;
}
.main-content #pricing .pricing-options .pricing-option .fake-button {
  display: inline-block;
  width: 100%;
  padding: 8px;
  font-size: 24px;
  color: #000;
  background-color: transparent;
  border: 1px solid #5057ff;
  border-radius: 100px;
  text-align: center;
}
.main-content #pricing .pricing-options .pricing-option p {
  font-size: 24px;
  color: #000;
  margin: 10px 0;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0%;
}
.main-content #pricing .pricing-options .pricing-option ul {
  list-style: none;
  margin: 15px 0;
  text-align: left;
  padding-bottom: 3px;
}
.main-content #pricing .pricing-options .pricing-option li {
  margin: 15px 0;
  font-size: 16px;
  letter-spacing: 0%;
  line-height: 140%;
  font-weight: normal;
}
.main-content #pricing .pricing-options .pricing-option.best-seller {
  border: 5px solid #5057ff;
  background-color: #f5f6fa;
  transform: translateY(-20px);
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
}
.main-content #pricing .pricing-options .lif {
  margin: 15px 0;
  font-size: 16px;
  letter-spacing: 0%;
  line-height: 140%;
  font-weight: 600;
}
.main-content #pricing .pricing-options .check-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 12px;
}
.main-content #pricing .pricing-options button:hover {
  background-color: #0056b3;
}
@media (min-width: 1024px) {
  .main-content #pricing .lines {
    position: absolute;
    top: 96px;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100px;
  }
  .main-content #pricing .lines * {
    background-color: #5057ff;
    height: 8px;
    width: 150px;
  }
  .main-content #pricing .lines *:nth-child(2) {
    width: 200px;
  }
  .main-content #pricing .lines *:nth-child(3) {
    width: 180px;
  }
}
@media (min-width: 640px) {
  .main-content #pricing .pricing-options {
    transform: translateY(0px);
  }
  .main-content #pricing .pricing-options .pricing-option.best-seller {
    transform: translateY(0px);
  }
}
@media (min-width: 1024px) {
  .main-content #pricing .pricing-options {
    transform: translateY(-20px);
  }
  .main-content #pricing .pricing-options .pricing-option.best-seller {
    transform: translateY(-20px);
  }
}
@media (max-width: 640px) {
  .main-content #pricing .pricing-options {
    transform: translateY(0px);
  }
  .main-content #pricing .pricing-options .pricing-option.best-seller {
    transform: translateY(0px);
  }
}
.main-content #review-tested-by {
  background-color: #f5f6fa;
}
.main-content #review-tested-by > div {
  max-width: 1200px;
  margin: 0 auto;
}
.main-content #review-tested-by h2 {
  font-size: 18px;
}
.main-content #review-tested-by .review-list {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 48px;
  justify-items: center;
  margin-top: 48px;
  width: 100%;
}
.main-content #review-tested-by .review-list img {
  height: 70px;
}
.main-content #review-tested-by .review-list .vertical {
  height: auto;
  width: 100px;
}
@media (min-width: 1024px) {
  .main-content #review-tested-by .review-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.main-content #how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f6fa;
}
.main-content #how-it-works > div {
  border-radius: 10px;
  background-color: #fff;
  padding: 36px;
}
.main-content #how-it-works .steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px; /* Adjust gap to a reasonable size for spacing */
  padding: 5px 0px 5px 0px; /* Adds padding at the top and bottom */
  margin-top: 10px;
  text-align: center;
  width: 100%;
}
.main-content #how-it-works .steps .steps-square {
  min-width: 34px;
  display: flex;
  height: 41px;
  border: 1px solid #c29225;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.main-content #how-it-works .steps h3 {
  color: #c29225;
  font-size: 18px;
}
.main-content #how-it-works .steps p {
  text-align: left;
  font-size: 18px;
  line-height: 1.4rem;
}
.main-content #quotes {
  margin: 96px 0;
  background-color: #fff;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o container */
  align-items: center; /* Centraliza verticalmente o container */
  text-align: center;
}
.main-content #quotes .quotes-container {
  display: flex;
  align-items: center; /* Centraliza verticalmente as aspas e o texto */
}
@media (min-width: 768px) {
  .main-content #quotes .quotes-container {
    max-width: 76%;
  }
}
@media (min-width: 1024px) {
  .main-content #quotes .quotes-container {
    max-width: 64%;
  }
}
@media (min-width: 1280px) {
  .main-content #quotes .quotes-container {
    max-width: 52%;
  }
}
@media (min-width: 1536px) {
  .main-content #quotes .quotes-container {
    max-width: 40%;
  }
}
.main-content #quotes .quote-mark {
  width: 20px; /* Ajuste o tamanho da imagem da aspa se necessário */
  opacity: 1; /* Adiciona transparência às aspas */
}
.main-content #quotes .quote {
  padding: 0 40px; /* Adiciona espaçamento entre o texto e as aspas */
}
.main-content #quotes .quote br {
  display: none;
}
.main-content #quotes .quote h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 30px;
}
.main-content #quotes .quote h4 {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}
.main-content .scroll-to-top {
  position: fixed;
  bottom: 84px; /* Adjust as needed */
  right: 62px; /* Adjust as needed */
  background-color: #5057ff; /* Button color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 50%; /* Makes the button round */
  width: 50px; /* Button width */
  height: 50px; /* Button height */
  font-size: 24px; /* Font size for the arrow */
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3); /* Optional shadow */
  display: none;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s; /* Smooth transition */
}
.main-content .scroll-to-top:hover {
  background-color: #5057ff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}
.landing-page--footer {
  background-color: #444ada;
  padding: 32px 0;
  color: #fff;
  margin-top: 48px;
}
.landing-page--footer .container.landing-page {
  margin: 30px 0 30px;
}
.landing-page--footer .container.landing-page .footer--top {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255,255,255,0.25);
}
.landing-page--footer .container.landing-page .footer--top > * + * {
  margin-left: 0;
  margin-top: 48px;
}
.landing-page--footer .container.landing-page .footer--top .custom-contact {
  font-size: 18px;
  line-height: 1.5;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.landing-page--footer .container.landing-page .footer--top .custom-contact a {
  display: inline-block;
  color: #fff;
  font-weight: 600;
}
.landing-page--footer .container.landing-page .footer--bottom {
  padding: 24px 0 0;
}
.landing-page--footer .container.landing-page .footer--bottom .copyright {
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
}
.landing-page--footer .container.landing-page .footer--links {
  text-align: center;
  margin-top: 16px;
  line-height: 1.4rem;
}
.landing-page--footer .container.landing-page .footer--links > * + * {
  margin-left: 8px;
}
.landing-page--footer .container.landing-page .footer--links a {
  color: #fff;
  font-size: 12px;
  text-decoration: underline;
}
@media (min-width: 1280px) {
  .landing-page--footer .container.landing-page {
    margin: 0 auto;
  }
  .landing-page--footer .container.landing-page .footer--top {
    flex-direction: row;
  }
  .landing-page--footer .container.landing-page .footer--top * {
    margin: 0;
    text-align: left;
  }
}
.announcement {
  width: 100%;
  background-color: #5057ff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 48px;
  margin: 0 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.announcement .announcement-text {
  align-self: center;
  line-height: 1.5;
}
.announcement button {
  align-self: start;
  position: absolute;
  right: 16px;
}
.faq-header {
  padding: 16px 0 32px 0;
}
.faq-header h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 8px;
}
.faq-header h3 {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .faq-header h1 {
    font-size: 32px;
    font-weight: 600;
  }
  .faq-header h3 {
    font-size: 24px;
    font-weight: 500;
  }
}
.faq-items--list {
  margin-bottom: 64px;
}
.faq-items--list > * + * {
  margin-top: 32px;
}
.affiliate-text {
  font-size: 30px;
  margin-bottom: 16px;
}
.affiliate-helper-text {
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 8px;
}
.affiliate-card {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.affiliate-card-h2 {
  font-size: 35px;
}
.affiliate-text-small {
  font-size: 20px;
}
.affiliate-item > h2 {
  font-size: 30px;
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .affiliate-item {
    padding: 16px;
  }
  .affiliate-item > h2 {
    font-size: 20px;
    font-weight: 600;
  }
}
.faq-item {
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e6e7ec;
}
.faq-item > h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .faq-item {
    padding: 16px;
  }
  .faq-item > h2 {
    font-size: 20px;
    font-weight: 600;
  }
}
.faq--zero-content-placeholder {
  padding: 64px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #576975;
}
.faq-contentful__wrapper > * + * {
  margin-top: 16px;
}
.faq-contentful__wrapper hr {
  margin: 24px auto !important;
}
.faq-contentful__wrapper p {
  line-height: 1.5;
}
.faq-contentful__wrapper p,
.faq-contentful__wrapper ul,
.faq-contentful__wrapper ol {
  color: #576975;
  font-size: 18px;
  font-weight: 500;
}
.faq-contentful__wrapper ul,
.faq-contentful__wrapper ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  line-height: 1.25;
}
.faq-contentful__wrapper ul > * + *,
.faq-contentful__wrapper ol > * + * {
  margin-top: 8px;
}
.faq-contentful__wrapper ul {
  list-style: disc;
}
.faq-contentful__wrapper ol {
  list-style: decimal;
}
.faq-contentful__wrapper pre,
.faq-contentful__wrapper code {
  padding: 4px 8px;
  background-color: #e6e7ec;
  border-radius: 4px;
}
.faq-contentful__wrapper code {
  font-family: 'Source Code Pro', monospace;
  font-size: 16px;
  color: #2d3e4e;
  line-height: 1.25;
}
.faq-contentful__wrapper pre > code {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  font-size: 18px;
}
.faq-contentful__wrapper thead {
  border-bottom: 1px solid #576975;
}
.faq-contentful__wrapper tbody > tr:nth-child(odd) {
  background-color: #e6e7ec;
}
.faq-contentful__wrapper th,
.faq-contentful__wrapper td {
  padding: 8px 16px;
  color: #2d3e4e;
}
.faq-contentful__wrapper th {
  font-weight: 600;
  text-align: left;
}
@media (max-width: 768px) {
  .faq-contentful__wrapper p {
    font-size: 16px;
  }
}
.ballnamic--gift-page .purchase {
  font-size: 14px;
  font-weight: 400;
  margin-top: 30px;
  color: #000;
  line-height: 1.4;
  width: 100%;
  text-align: center;
}
.ballnamic--gift-page .redeem-steps-container {
  text-align: center;
  padding: 20px 20px 0 20px;
}
.ballnamic--gift-page .steps-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px auto;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.ballnamic--gift-page .step {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  gap: 16px;
}
.ballnamic--gift-page .step-number {
  width: 32px;
  height: 32px;
  background-color: #5057ff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.ballnamic--gift-page .step-text {
  font-size: 18px;
  color: #37424a;
  flex: 1;
  text-align: left;
}
.ballnamic--gift-page .gift-page {
  padding: 20px 20px 0 20px;
}
@media (max-width: 768px) {
  .ballnamic--gift-page .gift-page {
    margin-top: 20px;
  }
}
.ballnamic--gift-page .gift-page .gift-voucher-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: calc(50% - 30px);
  margin-bottom: 60px;
  flex-grow: 1;
}
.ballnamic--gift-page .gift-page .gift-voucher-text-wrapper p {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 1.4;
}
.ballnamic--gift-page .gift-page .gift-voucher-text-wrapper h2 {
  color: #000;
  font-size: 22px;
}
.ballnamic--gift-page .gift-page .gift-voucher-text-wrapper h1 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
}
.ballnamic--gift-page .gift-page .gift-inner {
  max-width: 500px;
  width: auto;
  margin: 0 auto;
  padding: 20px 40px 15px 40px;
  background-color: #fff;
  border-radius: 0px;
  border: 1.5px solid #5057ff;
}
.ballnamic--gift-page .gift-page .gift-inner p {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.4;
  width: 100%;
}
@media (max-width: 1024px) {
  .ballnamic--gift-page .gift-page .gift-inner {
    padding: 50px;
  }
}
@media (max-width: 768px) {
  .ballnamic--gift-page .gift-page .gift-inner {
    padding: 40px;
  }
}
@media (max-width: 480px) {
  .ballnamic--gift-page .gift-page .gift-inner {
    padding: 28px;
    gap: 34px;
  }
}
.ballnamic--gift-page .gift-page .gift-inner .gift-voucher-text-wrapper-login {
  flex-basis: calc(50% - 30px);
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.ballnamic--gift-page .gift-page .gift-inner .gift-voucher-text-wrapper-login p {
  color: #576975;
  font-size: 17px;
  font-weight: 400;
  margin-top: 20px;
  line-height: 1.4;
  width: 100%;
}
.ballnamic--gift-page .gift-page .gift-inner .gift-voucher-button-wrapper {
  flex-basis: 100%;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column; /* Stack the children vertically */
  align-items: center;
}
@media (max-width: 992px) {
  .ballnamic--gift-page .gift-page .gift-inner .gift-voucher-img-wrapper,
  .ballnamic--gift-page .gift-page .gift-inner .gift-voucher-text-wrapper {
    flex-basis: 100%;
  }
}
.ballnamic--gift-page .landing-page--footer {
  margin-top: 0;
}
.ballnamic--gift-page .bottom-cta__wrapper {
  margin-top: 70px;
}
@media (max-width: 768px) {
  .ballnamic--gift-page .bottom-cta__wrapper {
    margin-top: 30px;
  }
}
.faq-page {
  margin: 0px 20px 20px 20px;
}
.voucher-modal .modal--title {
  margin-bottom: 4px;
}
.voucher-modal .input-container,
.voucher-modal .buttonNext-container {
  margin-top: 32px;
}
.voucher-modal .helper-text {
  color: #616a70;
  font-size: 16px;
  font-weight: 600;
}
.voucher-modal .helper-text ul {
  font-weight: 600;
  list-style: disc;
  padding-inline-start: 20px;
}
.voucher-modal .emailReceiveWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
  padding: 20px 0;
  border-top: solid 1px #b6bdc2;
}
.voucher-modal .emailReceiveWrapper .summaryEmail {
  font-weight: 600;
}
.voucher-modal .summaryPayButton {
  margin-top: 32px;
}
